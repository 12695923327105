// Atoms related to conversations.

import {
  addMinutes,
  minutesToMilliseconds,
  secondsToMilliseconds,
} from 'date-fns';
import { atom, selectorFamily, useRecoilValue } from 'recoil';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import { RoomConvoKey } from 'renderer/models/Keys';
import {
  ambientSoundContextAtom,
  AmbientSoundMode,
  AmbientSoundVolumeConfig,
  ConvoAmbientSoundMode,
  convoAmbientSoundModeAtom,
} from './ambientSound';
import { roomAtom } from './room';

export const MaxRecentLeftConvoLength = 5;
export const recentlyLeftConvoAtom = atom<string[]>({
  key: 'recentlyLeftConvoAtom',
  default: [],
});

const convoDataForAmbientSoundAtom = selectorFamily<
  {
    isEvent: boolean;
    createdTime: number;
  },
  RoomConvoKey
>({
  key: 'convoDataForAmbientSoundAtom',
  get:
    (key) =>
    ({ get }) => {
      const { eventList, convoList } = get(roomAtom(key.roomId));
      return {
        isEvent: eventList.includes(key.convoId),
        createdTime:
          convoList.find(({ convoId }) => convoId === key.convoId)?.created ??
          -1,
      };
    },
});

export const convoAmbientVolumeAtom = selectorFamily<
  {
    // Time stamp when to start the ramp up.
    startTime: number;
    // Duration of time to ramp up
    delayMs: number;
    // Volume reached at (starTime + delayMs)
    volume: number;
  },
  RoomConvoKey
>({
  key: 'convoAmbientSoundSettingsAtom',
  get:
    (param) =>
    ({ get }) => {
      const { tunedInVolume: maxVolume, mode } = get(ambientSoundContextAtom);
      const convoMode = get(
        convoAmbientSoundModeAtom({ convoId: param.convoId })
      );

      // The user has opted to tune in.
      if (convoMode === ConvoAmbientSoundMode.TuneIn)
        return { volume: maxVolume, delayMs: 0, startTime: 0 };
      // The user has opted to tune in out so mute the convo.
      if (convoMode === ConvoAmbientSoundMode.TuneOut)
        return { volume: 0, delayMs: 0, startTime: 0 };

      // Is self in a conversation.
      // TODO: Dont appoximate this with a mic, it should be using conversation context. This also crashes for some reason.
      // const selfMicActive = useRecoilValue(
      //   buttonActive({ button: ButtonType.MIC })
      // );
      // const tunedInVolume = selfMicActive
      //   ? Math.min(maxVolume / 2, AmbientSoundVolumeConfig.Min)
      //   : maxVolume;
      const tunedInVolume = maxVolume;
      // If the user recently left the conversation, then we always
      // want it at full ambient volume.
      if (get(recentlyLeftConvoAtom).includes(param.convoId)) {
        return { volume: tunedInVolume, delayMs: 0, startTime: 0 };
      }
      const { isEvent, createdTime } = get(convoDataForAmbientSoundAtom(param));

      // If the conversation is an event, we want it at full volume.
      if (isEvent) return { volume: tunedInVolume, delayMs: 0, startTime: 0 };

      // For other conversations, its based on the mode.
      switch (mode) {
        case AmbientSoundMode.LONG_CONVERSATIONS:
          return {
            volume: tunedInVolume,
            delayMs: minutesToMilliseconds(3),
            startTime: addMinutes(createdTime, 5).getTime(),
          };
        case AmbientSoundMode.ALL_CONVERSATIONS:
          return {
            volume: tunedInVolume,
            delayMs: secondsToMilliseconds(30),
            startTime: createdTime,
          };
        case AmbientSoundMode.NEVER:
        default:
          return { volume: 0, delayMs: 0, startTime: 0 };
      }
    },
});
