/* eslint-disable no-nested-ternary */
import ScreenShareRoundedIcon from '@mui/icons-material/ScreenShareRounded';
import {
  AddCircleRounded,
  RemoveCircleOutline,
  RemoveCircleRounded,
} from '@mui/icons-material';
import {
  memo,
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { PuffLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import {
  ConnectionStatusEnum,
  isUserMicWaitingToConnectAtom,
  connectionStatusAtom,
  isUserWithBadConnectionAtom,
  MicStatusEnum,
  userMicStatusAtom,
} from 'renderer/atoms/connectionStatus';
import { selfGlooUserAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import { userDisplayNameAtom, roomUserAtom } from 'renderer/atoms/room';
import { isUserInSelfUserTableWithVideoAtom } from 'renderer/atoms/userUtils';
import { videoCamActiveForUserAtom } from 'renderer/call/components/atoms/videoCameraAtoms';
import { availableStatusAtom } from 'renderer/connection/state';
import { userSpeakingAtom } from 'renderer/connection/voiceCallState';
import clsx from 'clsx';
import { bubbleExpandedAtom } from 'renderer/atoms/bubbleGrid';
import { GlooUser as GlooUserInterface, TeamUser } from 'renderer/models/Api';
import { BiCrown } from 'react-icons/bi';
import { useShouldJoinCall } from 'renderer/call/components/JoinCallMonitor';
import { useScreenShareViewModal } from '../Modals/screenshare/ViewScreenShareModal';
import { Tooltip } from '../Tooltip';
import { Avatarv2 } from './Avatarv2';
import { VideoStream } from './camera/VideoStream';
import { BottomRightStatusWrapper } from './subcomponents/BottomRight';
import {
  ConnectionStatus,
  MiniOfflineStatus,
  MiniOnlineStatus,
  OfflineStatus,
  OnlineStatus,
} from './subcomponents/BottomRight/ConnectionStatus';
import { MicStatus } from './subcomponents/BottomRight/MicStatus';
import { NudgeIndicator } from './subcomponents/NudgeIndicator';
import { ScreenshareStatusButton } from './subcomponents/screenshare/ScreenshareStatusButton';
import { DirectMessageButton } from './subcomponents/DirectMessageButtons';
import { NameTag } from './subcomponents/NameTag';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const GlooUser = ({
  roomId,
  userId,
  miniView = false,
}: {
  userId: string;
  roomId: string;
  miniView?: boolean;
}) => {
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const isSelfUserAvailable = useRecoilValue(availableStatusAtom);
  const isSelfUser = useRecoilValue(selfUserIdAtom) === userId;

  const connectionState = useRecoilValue(
    connectionStatusAtom({ roomId, userId })
  );
  const { shouldJoinCall } = useShouldJoinCall({ roomId });
  const isBadConnection =
    useRecoilValue(isUserWithBadConnectionAtom({ roomId, userId })) &&
    shouldJoinCall;

  const micStatus = useRecoilValue(userMicStatusAtom({ roomId, userId }));
  const isMicWaitingToConnect = useRecoilValue(
    isUserMicWaitingToConnectAtom({ roomId, userId })
  );
  const isSpeaking = useRecoilValue(userSpeakingAtom({ roomId, userId }));

  const isScreensharing = roomUser.convo.devices.screen;
  const { showModal: showScreenshare } = useScreenShareViewModal();

  const displayName = useRecoilValue(userDisplayNameAtom({ roomId, userId }));

  const isCamActive = useRecoilValue(
    videoCamActiveForUserAtom({ roomId, userId })
  );
  const isUserInSelfUserTableWVideo = useRecoilValue(
    isUserInSelfUserTableWithVideoAtom({ roomId, userId })
  );
  const { width: windowWidth } = useWindowDimensions();
  const bubbleExpanded = useRecoilValue(
    bubbleExpandedAtom({ convoId: roomUser.convo.convoId ?? 'NONE', roomId })
  );

  const isOnline = [
    ConnectionStatusEnum.Online,
    ConnectionStatusEnum.Connecting,
  ].includes(connectionState);
  const isConnectionTransition = [
    ConnectionStatusEnum.Connecting,
    ConnectionStatusEnum.Disconnecting,
  ].includes(connectionState);

  const isAtSelfUserTable = roomUser.convo.withPrimaryUser;
  const isBusy = roomUser.busy && !roomUser.convo.active;
  const aloneAtTable = !!(
    roomUser.convo.active === false && roomUser.convo.convoId !== undefined
  );
  const isScreenshareButtonDisabled =
    !isAtSelfUserTable || !isSelfUserAvailable;
  const displayAlways = !miniView && (isSelfUser || roomUser.convo.active);

  if (!isOnline) {
    return (
      <GlooOfflineUserWithProps
        userId={userId}
        roomId={roomId}
        iconSizePx={isSelfUser ? 44 : 36}
        photoUrl={roomUser.profile.photoUrl}
        fullName={roomUser.profile.displayName}
        displayName={displayName}
        displayNameAlways={displayAlways}
        isConnectionTransition={isConnectionTransition}
        membership={{ isDeleted: false, invite: roomUser.invitedUser }}
      />
    );
  }

  const isWindowLarge = windowWidth > 1024;
  const normalIconSize =
    (isUserInSelfUserTableWVideo ? (isWindowLarge ? 140 : 100) : 44) +
    (bubbleExpanded && isUserInSelfUserTableWVideo
      ? isWindowLarge
        ? 190
        : 48
      : 0);
  // TODO: if many users in the convo make the icons smaller

  const iconSizePx = miniView ? 24 : normalIconSize;

  return (
    <GlooUserWithProps
      userId={userId}
      roomId={roomId}
      isSelfUser={isSelfUser}
      iconSizePx={iconSizePx}
      isSpeaking={isSpeaking}
      micStatus={micStatus}
      isConvoActive={roomUser.convo.active}
      // a hack for now since in prev versions of gloo
      // users not in a convo can have their mic on so we gotta
      // render an actual white background.
      renderMicBackground={
        aloneAtTable &&
        ![MicStatusEnum.WaitingForOff, MicStatusEnum.Off].includes(micStatus)
      }
      isBusy={isBusy}
      isMicWaitingToConnect={isMicWaitingToConnect}
      isBadConnection={isBadConnection}
      isAtSelfUserTable={isAtSelfUserTable}
      displayName={displayName}
      displayNameAlways={displayAlways}
      isConnectionTransition={isConnectionTransition}
      isScreensharing={isScreensharing}
      isScreenshareButtonDisabled={isScreenshareButtonDisabled}
      screenshareButtonHandler={() => {
        showScreenshare(roomId, userId);
      }}
      isCamActive={isCamActive}
      isBubbleExpanded={bubbleExpanded && isUserInSelfUserTableWVideo}
    >
      {/* passing this as a child to try to get less rerenders */}
      <AvatarWithVideoContainer
        isSelfUser={isSelfUser}
        isCamActive={isCamActive}
        userId={userId}
        roomId={roomId}
        isAtSelfUserTable={isAtSelfUserTable}
        screenshareButtonHandler={() => {
          showScreenshare(roomId, userId);
        }}
        isScreensharing={isScreensharing}
        isEvent={roomUser.convo?.isEvent || false}
        isScreenshareButtonDisabled={isScreenshareButtonDisabled}
        photoUrl={roomUser.profile.photoUrl}
        iconSizePx={iconSizePx}
        fullName={roomUser.profile.displayName}
        isBubbleExpanded={bubbleExpanded}
      />
    </GlooUserWithProps>
  );
};

/** Raw icon with configurable props and no recoil dependency for usage
 * in notification window for example
 */
const GlooUserWithProps = ({
  userId,
  roomId,
  isSelfUser,
  iconSizePx = 44,
  isSpeaking,
  micStatus,
  renderMicBackground,
  isBusy,
  isScreensharing,
  isScreenshareButtonDisabled,
  screenshareButtonHandler,
  isCamActive,
  isMicWaitingToConnect,
  isBadConnection = false,
  displayName,
  displayNameAlways,
  isAtSelfUserTable,
  isConnectionTransition,
  isConvoActive,
  children,
  isBubbleExpanded,
}: {
  userId: string;
  roomId: string;
  isSelfUser: boolean;
  displayName: string;
  displayNameAlways: boolean;
  iconSizePx: number;
  isSpeaking: boolean;
  isScreensharing: boolean;
  isScreenshareButtonDisabled: boolean;
  screenshareButtonHandler?: () => void;
  isCamActive: boolean;
  micStatus: MicStatusEnum;
  isBusy: boolean;
  isMicWaitingToConnect?: boolean;
  renderMicBackground: boolean;
  isBadConnection?: boolean;
  isAtSelfUserTable: boolean;
  isConnectionTransition: boolean;
  children: ReactElement;
  isConvoActive: boolean;
  isBubbleExpanded: boolean;
}) => {
  return (
    <>
      {/* circle with ring (the container) */}
      <div
        className={clsx('relative flex group ring-white-1 bg-inherit', [
          isBubbleExpanded ? '' : 'rounded-full ring-2',
        ])}
        style={{ width: iconSizePx, height: iconSizePx }}
      >
        <div className="absolute flex w-full h-full bg-transparent">
          <>
            {children}

            {isConnectionTransition && (
              <div className="absolute w-full h-full rounded-full bg-blue-lightest-2/70">
                <PuffLoader size={iconSizePx} />
              </div>
            )}
          </>
        </div>
        {renderMicBackground && (
          <BottomRightStatusWrapper isBubbleExpanded={isBubbleExpanded}>
            <div className="w-full h-full rounded-full bg-white-1" />
          </BottomRightStatusWrapper>
        )}
        {isScreensharing && (
          <ScreenshareStatusButton
            isCamActive={isCamActive}
            isAtSelfUserTable={isAtSelfUserTable}
            screenshareButtonHandler={screenshareButtonHandler}
            isScreensharing={isScreensharing}
            screenshareButtonDisabled={isScreenshareButtonDisabled}
          />
        )}
        <NameTag name={displayName} displayAlways={displayNameAlways} />
        <NudgeIndicator userId={userId} roomId={roomId} />

        {![MicStatusEnum.WaitingForOff, MicStatusEnum.Off].includes(
          micStatus
        ) && isConvoActive ? (
          <BottomRightStatusWrapper isBubbleExpanded={isBubbleExpanded}>
            <MicStatus
              isAtSelfUserTable={isAtSelfUserTable}
              isSpeaking={isSpeaking}
              micStatus={micStatus}
            />
          </BottomRightStatusWrapper>
        ) : (
          <OnlineStatus isBusy={isBusy} micStatus={micStatus} />
        )}
        <ConnectionStatus
          isBubbleExpanded={isBubbleExpanded}
          isBadConnection={isBadConnection}
        />
      </div>
    </>
  );
};

export const GlooSelfUserWithStatus = ({
  iconSizePx,
  status,
}: {
  iconSizePx: number;
  status: GlooUserInterface['status'];
}) => {
  const selfUser = useRecoilValue(selfGlooUserAtom);

  return (
    <>
      {/* circle with ring (the container) */}
      <div
        className="relative flex rounded-full group ring-2 ring-white-1/30 bg-inherit"
        style={{ width: iconSizePx, height: iconSizePx }}
      >
        <div className="absolute flex w-full h-full bg-transparent">
          <Avatarv2
            photoUrl={selfUser.profile.photoUrl}
            iconSizePx={iconSizePx}
            name={selfUser.profile.displayName}
          />
          {/* {isConnectionTransition && (
          <div className="absolute w-full h-full rounded-full bg-blue-lightest-2/70">
            <PuffLoader size={iconSizePx} />
          </div>
        )} */}
        </div>
        {status.online ? (
          <OnlineStatus
            isBusy={status.details.inConvo || status.details.idle !== undefined}
            micStatus={MicStatusEnum.Off}
          />
        ) : (
          <OfflineStatus membership={{ isDeleted: false }} />
        )}
      </div>
    </>
  );
};

export const GlooUserSidePanel = ({
  userId,
  roomId,
  iconSizePx,
  withStatus = false,
}: {
  userId: string;
  roomId: string;
  iconSizePx: number;
  withStatus?: boolean;
}) => {
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const isScreensharing = roomUser.convo.devices.screen;
  const isMicOn = roomUser.convo.devices.mic;

  // These two fetch from agora
  const micStatus = useRecoilValue(userMicStatusAtom({ roomId, userId }));
  const isSpeaking = useRecoilValue(userSpeakingAtom({ roomId, userId }));

  const isBusy = roomUser.busy;
  const isAtSelfUserTable = roomUser.convo.withPrimaryUser;
  return (
    <div
      className="relative flex rounded-full group bg-inherit"
      style={{ width: iconSizePx, height: iconSizePx }}
    >
      <div className="absolute flex w-full h-full bg-transparent">
        <Avatarv2
          photoUrl={roomUser.profile.photoUrl}
          iconSizePx={iconSizePx}
          name={roomUser.profile.displayName}
        />
      </div>
      {withStatus &&
        (roomUser.online ? (
          <MiniOnlineStatus isBusy={isBusy} micStatus={micStatus} />
        ) : (
          <MiniOfflineStatus
            membership={{ isDeleted: false, invite: roomUser.invitedUser }}
          />
        ))}
      {!withStatus && isMicOn && (
        <div className="absolute flex w-[50%] h-[50%] -bottom-[1px] -right-[1px] rounded-full bg-neutral-2">
          <MicStatus
            isAtSelfUserTable={isAtSelfUserTable}
            isSpeaking={isSpeaking}
            micStatus={micStatus}
          />
        </div>
      )}
      {isScreensharing && (
        <div className="absolute flex w-[50%] h-[50%] -top-[1px] -right-[1px] rounded-full bg-neutral-2 items-center justify-center">
          <ScreenShareRoundedIcon className="text-black-lightest !w-[75%] !h-fit" />
        </div>
      )}
      <NudgeIndicator
        userId={userId}
        roomId={roomId}
        mini
        enableHover={false}
      />
      {/* TODO: group-hover not really working well */}
      {/* <NameTag name={displayName} displayAlways={false} /> */}
    </div>
  );
};

const GlooOfflineUserWithProps = ({
  iconSizePx = 44,
  userId,
  roomId,
  photoUrl,
  displayName,
  displayNameAlways,
  isConnectionTransition,
  fullName,
  membership,
}: {
  userId: string;
  roomId: string;
  photoUrl?: string;
  displayName: string;
  fullName: string;
  displayNameAlways: boolean;
  iconSizePx: number;
  isConnectionTransition: boolean;
  membership: TeamUser['membership'];
}) => {
  return (
    <>
      {/* circle with ring (the container) */}
      <div
        className="relative flex overflow-x-visible rounded-full group ring-2 ring-white-1 bg-inherit"
        style={{ width: `${iconSizePx}px`, height: `${iconSizePx}px` }}
      >
        <div className="flex w-full h-full bg-transparent">
          <Avatarv2
            photoUrl={photoUrl}
            iconSizePx={iconSizePx}
            name={fullName}
          />
          {isConnectionTransition ? (
            <div className="absolute w-full h-full rounded-full ">
              <PuffLoader size={iconSizePx} />
            </div>
          ) : (
            <div className="absolute w-full h-full rounded-full bg-blue-lightest-2/40" />
          )}
        </div>
        <OfflineStatus membership={membership} />
        <NudgeIndicator userId={userId} roomId={roomId} mini />
        <NameTag name={displayName} displayAlways={displayNameAlways} />
      </div>
    </>
  );
};

export const GlooTeamSettingsUserWithProps = ({
  userId,
  teamId,
  iconSizePx = 44,
  photoUrl,
  displayName,
  email,
  isMe,
  isAdmin,
  isPrimaryAdmin,
  isOnline,
  slack,
  isBusy,
  micStatus,
  children,
  membership,
  withSelfAdminPowers = false,
}: {
  userId: string;
  teamId: string;
  photoUrl?: string;
  displayName: string;
  iconSizePx: number;
  isAdmin: boolean;
  isPrimaryAdmin: boolean;
  isMe: boolean;
  email: string;
  withSelfAdminPowers?: boolean;
  isOnline: boolean;
  slack: boolean;
  isBusy: boolean;
  micStatus: MicStatusEnum;
  membership: TeamUser['membership'];
} & PropsWithChildren) => {
  return (
    <div className="flex flex-row items-center gap-2 w-fit text-black-lighter">
      {/* circle with ring (the container) */}
      <div
        className="relative flex overflow-x-visible rounded-full group ring-2 ring-white-1 bg-inherit"
        style={{ width: `${iconSizePx}px`, height: `${iconSizePx}px` }}
      >
        <div className="flex w-full h-full bg-transparent">
          <Avatarv2
            photoUrl={photoUrl}
            iconSizePx={iconSizePx}
            name={displayName}
          />
          {isOnline ? (
            <OnlineStatus isBusy={isBusy} micStatus={micStatus} />
          ) : (
            <OfflineStatus membership={membership} />
          )}
        </div>
      </div>
      <div className="flex flex-col font-normal text-gray-5">
        <span className="flex flex-row items-center gap-1 text-base">
          {displayName}
          {isMe && (
            <span className="px-2 text-xs rounded-md text-gray-4">YOU</span>
          )}
        </span>
        <div className="flex items-center gap-1 font-normal text-gray-5/60">
          {!isMe && <DirectMessageButton teamId={teamId} userId={userId} />}
          {isAdmin && (
            <div className="flex flex-row items-center gap-[1px]">
              <BiCrown className="w-4 h-4 text-purple-primary" />
              <span className="px-1 text-xs rounded-md w-fit">
                {isPrimaryAdmin && 'Primary'} Admin
              </span>
            </div>
          )}
          {membership.isDeleted && (
            <div className="flex flex-row items-center gap-[1px]">
              <span className="px-1 text-xs rounded-md w-fit">Removed</span>
            </div>
          )}
          {membership.invite?.status === 'PENDING' && (
            <div className="flex flex-row items-center gap-[1px]">
              <span className="px-1 text-xs rounded-md w-fit">Invited</span>
            </div>
          )}
          {(withSelfAdminPowers || isMe) && (
            <span className="text-xs italic truncate select-text">{email}</span>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export const GlooInviteUserWithProps = ({
  iconSizePx = 44,
  photoUrl,
  displayName,
  userInRoom,
}: {
  photoUrl?: string;
  displayName: string;
  userInRoom: boolean;
  iconSizePx: number;
}) => {
  return (
    <>
      {/* circle with ring (the container) */}
      <div
        className="relative flex overflow-x-visible rounded-full group ring-2 ring-white-1 bg-inherit"
        style={{ width: `${iconSizePx}px`, height: `${iconSizePx}px` }}
      >
        <div className="flex w-full h-full bg-transparent">
          <Avatarv2
            photoUrl={photoUrl}
            iconSizePx={iconSizePx}
            name={displayName}
          />
          {userInRoom ? (
            <div className="absolute hidden w-full h-full px-[10px] py-[6px] rounded-full bg-red-primary/80 text-white-1 group-hover:block">
              <RemoveCircleRounded className="!w-5 !h-5" />
            </div>
          ) : (
            <div className="absolute hidden w-full h-full px-[10px] py-[6px] rounded-full bg-blue-primary/80 text-white-1 group-hover:block">
              <AddCircleRounded className="!w-5 !h-5" />
            </div>
          )}
        </div>
        <NameTag name={displayName} displayAlways />
      </div>
    </>
  );
};

export const ExcessUserCountCircle = ({
  iconSizePx,
  count,
  color,
}: {
  iconSizePx: number;
  count: number;
  color?: string;
}) => {
  return (
    <Tooltip message={`${count} other users`}>
      <div
        className={clsx(
          'relative flex rounded-full group bg-inherit',
          color ? 'ring-2 ring-inset' : 'ring-1 ring-white-1'
        )}
        style={{ width: iconSizePx, height: iconSizePx }}
      >
        <div
          className={clsx(
            'absolute flex items-center justify-center w-full h-full text-sm bg-transparent rounded-full',
            color ?? 'bg-neutral-500 text-white-1'
          )}
        >
          +{count}
        </div>
      </div>
    </Tooltip>
  );
};

export const AvatarWithVideoContainer = memo(
  ({
    userId,
    roomId,
    isAtSelfUserTable,
    isSelfUser,
    screenshareButtonHandler,
    isScreensharing,
    isScreenshareButtonDisabled,
    photoUrl,
    iconSizePx,
    fullName,
    isCamActive,
    isEvent,
    isBubbleExpanded,
  }: {
    userId: string;
    roomId: string;
    isSelfUser: boolean;
    isAtSelfUserTable: boolean;
    screenshareButtonHandler?: () => void;
    isScreensharing: boolean;
    isScreenshareButtonDisabled: boolean;
    photoUrl?: string;
    iconSizePx: number;
    fullName: string;
    isCamActive: boolean;
    isEvent: boolean;
    isBubbleExpanded: boolean;
  }) => {
    return (
      <>
        {isCamActive ? (
          <VideoStream
            userId={userId}
            roomId={roomId}
            isCamActive={isCamActive}
            isSelfUser={isSelfUser}
            isEvent={isEvent}
            isBubbleExpanded={isBubbleExpanded}
          />
        ) : (
          <Avatarv2
            photoUrl={photoUrl}
            iconSizePx={iconSizePx}
            name={fullName}
            isBubbleExpanded={isBubbleExpanded}
          />
        )}
      </>
    );
  }
);
