import clsx from 'clsx';
import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MicStatusEnum } from 'renderer/atoms/connectionStatus';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { selectedTeamKeyAtom, teamAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import {
  ModalTypes,
  selectedModalAtom,
  useModalErrors,
} from 'renderer/shared/Modals/atoms';
import { GlooTeamSettingsUserWithProps } from 'renderer/shared/user/GlooUser';
import { MdPersonAdd } from 'react-icons/md';
import { SubtitleButton } from 'renderer/shared/SimpleButton';

export const InviteTeammateButton = () => {
  const setModal = useSetRecoilState(selectedModalAtom);
  return (
    <SubtitleButton
      clickHandler={() => {
        setModal(ModalTypes.kTeamInviteModal);
      }}
    >
      <MdPersonAdd />
      <span>Add Teammates</span>
    </SubtitleButton>
  );
};

const MembersOverView: React.FC = () => {
  const { setModalError } = useModalErrors();
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const selectedTeamKey = useRecoilValue(selectedTeamKeyAtom);
  const team = useRecoilValue(teamAtom(selectedTeamKey));

  const modifyAdminMutation = trpc.useMutation(['teams.admin.modify'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
  });
  const resendEmail = trpc.useMutation(['teams.invite.resend'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
  });

  const members = useMemo(() => {
    return Array.from(team.members).sort((prev, curr) => {
      if (prev.userId === selfUserId) return -1;
      if (curr.userId === selfUserId) return 1;

      if (prev.membership.isDeleted === curr.membership.isDeleted) {
        if (prev.membership.invite === curr.membership.invite) {
          if (prev.status.online === curr.status.online) {
            if (prev.permissions.admin === curr.permissions.admin) {
              return prev.profile.displayName.localeCompare(
                curr.profile.displayName
              );
            }
            return prev.permissions.admin ? -1 : 1;
          }
          return prev.status.online ? -1 : 1;
        }
        // Invited members should be second to last.
        return prev.membership.invite ? 1 : -1;
      }
      // Deleted members are last.
      return prev.membership.isDeleted ? 1 : -1;
    });
  }, [team.members, selfUserId]);

  const isSelfAdmin = !!members.find(
    (m) => m.userId === selfUserId && m.permissions.admin
  );

  return (
    <div className="flex w-full h-full p-2">
      <div className="flex flex-col w-full gap-3 pl-4 overflow-x-hidden overflow-y-scroll rounded-lg h-fit">
        {members.map((u) => {
          const isInvitedMember = !!u.membership.invite;
          const isActiveMember = !isInvitedMember && !u.membership.isDeleted;
          const isMe = u.userId === selfUserId;
          // Only admins can see removed users.
          if (u.membership.isDeleted && !isSelfAdmin) return null;
          return (
            <div className="flex flex-col gap-3 group" key={u.userId}>
              <div className="w-full border-t-[1px] border-gray-4/10" />
              <GlooTeamSettingsUserWithProps
                userId={u.userId}
                teamId={team.teamId}
                iconSizePx={32}
                photoUrl={u.profile.photoUrl}
                displayName={u.profile.displayName}
                isMe={isMe}
                isAdmin={u.permissions.admin}
                isPrimaryAdmin={u.permissions.primaryOwner}
                email={u.profile.email}
                withSelfAdminPowers={isSelfAdmin}
                slack={u.integrations.slack}
                isOnline={u.status.online}
                isBusy={u.status.details.busy}
                micStatus={
                  u.device?.mic?.roomId !== undefined
                    ? MicStatusEnum.On
                    : MicStatusEnum.Off
                }
                membership={u.membership}
              >
                {isInvitedMember && (
                  <button
                    type="button"
                    className={clsx(
                      'hidden px-1 text-xs rounded-md group-hover:block  text-white-1 w-fit bg-purple-primary/80 hover:bg-purple-primary/60'
                    )}
                    onClick={() =>
                      resendEmail.mutateAsync({
                        teamId: selectedTeamKey,
                        userId: u.userId,
                      })
                    }
                  >
                    Resend invite
                  </button>
                )}
                {isActiveMember && isSelfAdmin && (
                  <button
                    type="button"
                    className={clsx(
                      'hidden px-1 text-xs rounded-md group-hover:block  text-white-1 w-fit',
                      u.permissions.admin
                        ? 'bg-red-primary hover:bg-red-primary/80'
                        : 'bg-purple-primary/80 hover:bg-purple-primary/60'
                    )}
                    onClick={() =>
                      modifyAdminMutation.mutateAsync({
                        teamId: selectedTeamKey,
                        targetUserId: u.userId,
                        makeAdmin: !u.permissions.admin,
                      })
                    }
                  >
                    {u.permissions.admin ? 'Remove' : 'Make'} admin
                  </button>
                )}
              </GlooTeamSettingsUserWithProps>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MembersOverView;
