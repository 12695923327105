import { useRecoilValue } from 'recoil';
import { roomAtom, roomConvoActiveAtom } from 'renderer/atoms/room';
import { unreadMesssagesForChannelAtom } from 'renderer/chat/atoms';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { usePageAttributes } from 'renderer/hooks/usePageAttributes';
import { PageSelector } from 'renderer/atoms/pageselectors';
import { CalendarClock } from 'renderer/shared/icons/CalendarClock';
import { activeEventsAtom } from 'renderer/atoms/userUtils';
import { Tooltip } from 'renderer/shared/Tooltip';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import { teamAtom } from 'renderer/atoms/team';

export const TeamHomeTitle: React.FC<
  {
    selected: boolean;
    title: string;
    calendarIcon?: React.ReactNode;
  } & PropsWithChildren
> = ({ selected, title, children, calendarIcon }) => {
  let titleTextColor = null;
  if (selected) {
    titleTextColor = 'text-black-lighter font-semibold';
  }

  return (
    <div
      className={clsx('flex justify-start items-center gap-[6px]', [
        titleTextColor ?? 'text-inherit',
      ])}
    >
      {/* this wrapper div is magical and makes the icon not get smaller in some cases */}
      <div className="flex items-center">
        <div className="flex items-center w-4 h-4">{children}</div>
      </div>
      <div className="text-base capitalize truncate grow">{title}</div>
      <div className="justify-end">{calendarIcon}</div>
    </div>
  );
};

export const RoomListTitle: React.FC<{
  selected: boolean;
  roomId: string;
  isDm: boolean;
}> = ({ selected, roomId, isDm }) => {
  const room = useRecoilValue(roomAtom(roomId));
  const { title, icon } = usePageAttributes(PageSelector.TEAM_ROOM, room);

  const unreadMessageCount = useRecoilValue(
    unreadMesssagesForChannelAtom({ channelId: roomId })
  );
  const activeEvents = useRecoilValue(activeEventsAtom({ roomId }));
  const isConvoActive = useRecoilValue(roomConvoActiveAtom({ roomId }));
  return (
    <TeamHomeTitle
      title={title}
      selected={selected || unreadMessageCount > 0 || isConvoActive}
      calendarIcon={
        // TODO: change color when about to start
        <div className="flex">
          {activeEvents && activeEvents.length > 0 && (
            <Tooltip message="Meeting is ongoing">
              <CalendarClock className="text-orange-primary" />
            </Tooltip>
          )}
          {isConvoActive && (
            <Tooltip message="Listening to an active conversation">
              <div className="flex justify-center w-4 h-4">
                <LeakAddIcon className="animate-pulse !w-full !h-full text-purple-primary" />
              </div>
            </Tooltip>
          )}
          {unreadMessageCount > 0 && (
            <div className="flex justify-center w-4 h-4 text-xs rounded-md bg-gray-5 text-white-1">
              {unreadMessageCount > 9 ? '9+' : unreadMessageCount}
            </div>
          )}
        </div>
      }
    >
      {icon}
    </TeamHomeTitle>
  );
};
