import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { roomsExistAtom } from 'renderer/atoms/room';
import {
  availableStatusAtom,
  unavailableStatusReason,
} from 'renderer/connection/state';
import { GlooSelfUserWithStatus } from 'renderer/shared/user/GlooUser';
import { MusicPanel } from '../MusicPanel';
import { AmbientSoundPanel } from './AmbientSoundPanel';
import { MeetingDetectionPanel } from './MeetingDetectionPanel';
import { StatusToggle } from './StatusToggle';
import { UserIcon } from './UserIcon';

export const StatusPanel = () => {
  const available = useRecoilValue(availableStatusAtom);
  const roomsExist = useRecoilValue(roomsExistAtom);
  const unavilableStatusReasonMsg = useRecoilValue(unavailableStatusReason);

  return (
    <div className="flex flex-col items-center space-y-2 h-fit">
      <MusicPanel />

      {available && roomsExist && <AmbientSoundPanel />}

      <div
        className={clsx(
          'flex flex-col items-center justify-between w-full p-2 px-3 space-y-1 text-xs rounded-lg lg:text-base h-fit overflow-clip shadow-shadow/soft/card',
          available
            ? 'bg-white-1 text-gray-blue-dark-2'
            : 'bg-gray-5 text-white-1/80'
        )}
      >
        <div>{unavilableStatusReasonMsg}</div>
        <div className="flex flex-row items-center justify-between w-full">
          <UserIcon size={40} />
          <StatusToggle />
        </div>
      </div>
      <MeetingDetectionPanel />
    </div>
  );
};
