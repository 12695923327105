import { useEffect, useMemo, useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { convoAmbientVolumeAtom } from 'renderer/atoms/conversation';
import { roomUserAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import { avDeviceIdAtom } from 'renderer/atoms/settings';
import {
  makeFilterFarAway,
  setOverhearingFilter,
} from 'renderer/audio/OverhearingFilters';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import {
  lastTableSpeakTimeAtom,
  lastUserSpeakTimeAtom,
  convoHoverAtom,
  userSpeakingAtom,
  userSpeakingVolumeAtom,
} from 'renderer/connection/voiceCallState';
import { minVolumeTrigger } from 'renderer/constants';
import { RoomUserKey } from 'renderer/models/Keys';
import {
  IAudioContext,
  IBiquadFilterNode,
  IGainNode,
} from 'standardized-audio-context';
import { agoraSubscribedMicTrackAtom } from '../../atoms/CallStateAtoms';

const logger = LogCreator(LoggerNames.MIC);

export const AgoraUserAudio: React.FC<RoomUserKey> = ({ roomId, userId }) => {
  const track = useRecoilValue(agoraSubscribedMicTrackAtom({ roomId, userId }));

  const setRoomUserVolume = useRecoilCallback(
    ({ set, snapshot }) =>
      async (volume: number) => {
        const roomUser = await snapshot.getPromise(
          roomUserAtom({ roomId, userId })
        );

        const speaking = volume > minVolumeTrigger;
        set(userSpeakingVolumeAtom({ roomId, userId }), volume);
        set(userSpeakingAtom({ roomId, userId }), speaking);
        if (speaking) {
          const now = new Date();
          set(lastUserSpeakTimeAtom({ roomId, userId }), now);
          set(
            lastTableSpeakTimeAtom({
              roomId,
              convoId: roomUser.convo?.convoId || 'none',
            }),
            now
          );
        }
      },
    [roomId, userId]
  );

  const {
    convo: { convoId, withPrimaryUser: isInConvoWithSelfUser },
  } = useRecoilValue(roomUserAtom({ roomId, userId }));

  const hoveredConvo = useRecoilValue(convoHoverAtom);
  const convoHovered = useMemo(
    () =>
      !!(
        hoveredConvo &&
        hoveredConvo.roomId === roomId &&
        hoveredConvo.convoId === convoId
      ) && !isInConvoWithSelfUser,
    [convoId, hoveredConvo, roomId, isInConvoWithSelfUser]
  );
  const [convoEverHovered, setConvoEverHovered] = useState(convoHovered);
  useEffect(() => {
    setConvoEverHovered((prev) => prev || convoHovered);
  }, [convoHovered]);

  const ambientSoundVolume = useRecoilValue(
    convoAmbientVolumeAtom({ roomId, convoId: convoId ?? 'NONE' })
  );
  const now = useRecoilValue(
    intervaledDateTimeAtom(DateTimeIntervals.SECOND_30)
  );

  const targetAmbientVolume = useMemo(() => {
    if (ambientSoundVolume.startTime > now.getTime()) return 0;
    return ambientSoundVolume.volume;
  }, [ambientSoundVolume.startTime, ambientSoundVolume.volume, now]);

  const [gainNode, setGainNode] = useState<IGainNode<IAudioContext> | null>(
    null
  );
  const [audioContext, setAudioContext] = useState<IAudioContext | null>(null);
  const [filterNode, setFilterNode] =
    useState<IBiquadFilterNode<IAudioContext> | null>(null);

  const outputDeviceId = useRecoilValue(avDeviceIdAtom('output'));

  useEffect(() => {
    if (track) {
      track.setPlaybackDevice(outputDeviceId);
    }
  }, [outputDeviceId, track]);

  useEffect(() => {
    if (!track) return;
    logger.info('AgoraUserAudio set up', {
      roomId,
      userId,
      convoId,
    });
    if (isInConvoWithSelfUser) {
      if (!track.isPlaying) {
        track.setVolume(100);
        track.play();
      }
    } else {
      // enable audio when table is hovered as well even if
      // overhearing setting is disabled
      const data = setOverhearingFilter(track);
      setGainNode(data.gain);
      setAudioContext(data.audioContext);
      setFilterNode(data.filter);
      if (track.isPlaying) track.stop();
      return () => {
        // Reset the context we set.
        setAudioContext(null);
        setGainNode(null);
        setFilterNode(null);

        // Clean up data object.
        data.loopBack.loopback.destroy();
        data.streamSource.disconnect();
        data.audioContext.close();
        track.getMediaStreamTrack().enabled = true;
        track.play();
      };
    }
    // Don't depend on track as it always changes.
  }, [isInConvoWithSelfUser, track, convoId, roomId, userId]);

  const convoAmbientRampTimeMs = useMemo(
    // Once we've ever hovered over the conversation, always ramp down quickly.
    () => (convoEverHovered ? 1_000 : ambientSoundVolume.delayMs),
    [ambientSoundVolume.delayMs, convoEverHovered]
  );

  // Control the gain based off certain events
  // TODO: right now this resets everytime user unmutes.
  // so in future remember if we've already reached started adjusting
  // and just go from there. Or don't ramp up if convoId is old.
  useEffect(() => {
    if (!gainNode || !audioContext) {
      return;
    }

    // how slow the ramp up of this convo's volume will be
    // when window is in the background.
    // Used so that we don't startle people with a random convo.
    // We use smaller ramp up if your vol is already low
    const defaultDelaySecs = convoAmbientRampTimeMs / 1_000;
    const adjustedVol = targetAmbientVolume / 100;

    const adjustGain = ({ value, delay }: { value: number; delay: number }) => {
      const end = audioContext.currentTime + delay;
      if (delay === 0) {
        gainNode.gain.value = value;
      } else {
        // gainNode.gain.value = 0.005; // use this for exponential
        console.log('gain', value, delay);
        // if we use 0 it will error out
        gainNode.gain.exponentialRampToValueAtTime(
          value === 0 ? 0.001 : value,
          end
        );
        // we could potentially add more ramp up/downs here
      }
    };

    if (convoHovered) {
      // Ramp up to full volume while hovering within 1 second.
      adjustGain({ value: 0.75, delay: 1 });
    } else if (adjustedVol > 0) {
      adjustGain({ value: adjustedVol, delay: defaultDelaySecs });
    } else {
      // If we are ever setting the volume to 0, it should always be fast.
      adjustGain({ value: 0, delay: 0.5 });
    }
  }, [
    convoAmbientRampTimeMs,
    audioContext,
    convoHovered,
    gainNode,
    targetAmbientVolume,
  ]);

  // filters
  useEffect(() => {
    if (!filterNode) {
      return;
    }
    if (!convoHovered) {
      // if (isRoomSelected) {
      makeFilterFarAway(filterNode);
      // } else {
      //   // unselected room audio sounds like it's in another room
      //   makeFilterMuffled(filterNode);
      // }
    } else {
      // TODO: other properties we may need to reset here?
      filterNode.type = 'allpass';
    }
  }, [filterNode, convoHovered]);

  useEffect(() => {
    if (!track) return;

    const ret = setInterval(
      () => setRoomUserVolume(track.getVolumeLevel()),
      500
    );
    return () => {
      clearInterval(ret);
      setRoomUserVolume(0);
    };
  }, [track]);

  return <></>;
};
