import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import clsx from 'clsx';
import { MdAdd, MdClose } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';
import { useRoomAtomSetters } from 'renderer/atoms/room';
import {
  useMicToggles,
  localMicActiveAtom,
} from 'renderer/call/components/MicrophoneProvider';
import {
  useSceenshareToggles,
  selfScreenshareActiveAtom,
} from 'renderer/call/components/ScreenShareProvider';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { Room } from 'renderer/models/Api';
import { RoomKey } from 'renderer/models/Keys';
import { GradientButton } from 'renderer/shared/Buttons/helpers/GradientButton';
import { OutlineButton } from 'renderer/shared/Buttons/helpers/OutlineButton';
import { RoomIcon } from 'renderer/shared/icons/RoomIcon';
import { SubtitleButton } from 'renderer/shared/SimpleButton';
import { useModalErrors } from '../../atoms';
import { UsersPreview } from './UsersPreview';

export const RoomSearchPreview = ({
  room,
  isJoined,
  onJoinHandler,
  onLeaveHandler,
}: {
  isJoined: boolean;
  room: Room;
  onJoinHandler?: () => void;
  onLeaveHandler?: () => void;
}) => {
  const { selectRoom } = usePageSelector();
  return (
    <>
      <div className="w-full border-t-[1px] border-gray-4/10" />
      <div
        className={clsx(
          'relative flex flex-row items-center p-2 group hover:bg-gray-1',
          isJoined ? 'hover:cursor-pointer' : ''
        )}
        onClick={
          isJoined
            ? () => {
                selectRoom(room);
              }
            : undefined
        }
      >
        <div className="flex flex-col items-center justify-between gap-2">
          {/* title */}
          <div className="flex flex-row items-center justify-start w-full gap-2 text-left">
            <RoomIcon room={room} />
            <span className="text-lg font-semibold capitalize whitespace-nowrap">
              {room.name}
            </span>
            {room.description && (
              <>
                <span className="text-gray-4">·</span>
                <span className="flex-1 w-[60px] flex-grow pr-6 text-sm truncate text-gray-4">
                  {room.description}
                </span>
              </>
            )}
          </div>
          {/* description row */}
          <div className="flex flex-row items-center w-full truncate text-gray-4">
            <UsersPreview room={room} maxUsersToShow={10} />
          </div>
        </div>
        {room.type === 'DEFAULT' && (
          <div className="absolute right-2 lg:inset-0 lg:flex lg:justify-center lg:items-center lg:pl-40">
            <div className="hidden px-1 group-hover:block">
              {/* Buttons on the right */}

              {isJoined ? (
                <LeaveButton
                  roomId={room.roomId}
                  onLeaveHandler={onLeaveHandler}
                />
              ) : (
                <JoinButton
                  roomId={room.roomId}
                  onJoinHandler={onJoinHandler}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const JoinButton: React.FC<RoomKey & { onJoinHandler?: () => void }> = ({
  roomId,
  onJoinHandler,
}) => {
  const { selectRoom } = usePageSelector();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const { setModalError } = useModalErrors();
  const joinRoomMutation = trpc.useMutation(['rooms.join'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
    onSuccess: (data: Room) => {
      addOrUpdateRoom(data);
      selectRoom(data);
      if (onJoinHandler) {
        onJoinHandler();
      }
    },
  });
  return (
    <SubtitleButton clickHandler={() => joinRoomMutation.mutate({ roomId })}>
      <MdAdd />
      Join
    </SubtitleButton>
  );
};

const LeaveButton = ({
  roomId,
  onLeaveHandler,
}: {
  roomId: string;
  onLeaveHandler?: () => void;
}) => {
  const { setModalError } = useModalErrors();
  const { removeRoom } = useRoomAtomSetters();
  const leaveRoomMutation = trpc.useMutation(['rooms.leave'], {
    onError: (error) => {
      setModalError(error.message || 'Unknown error.');
    },
  });
  const { turnOffTrack: turnOffMic } = useMicToggles();
  const { turnOffTrack: turnOffScreenShare } = useSceenshareToggles();

  const leaveRoom = useRecoilCallback(
    ({ snapshot, set }) =>
      async (muation: typeof leaveRoomMutation) => {
        // const roomId = await snapshot.getPromise(selectedRoomKeyAtom);
        const micOn = await snapshot.getPromise(localMicActiveAtom({ roomId }));
        if (micOn) await turnOffMic();
        const screenShareOn = await snapshot.getPromise(
          selfScreenshareActiveAtom({ roomId })
        );
        if (screenShareOn) await turnOffScreenShare();

        await muation.mutateAsync({ roomId });
        removeRoom(roomId);
        // execute callback to refresh the list of items
        if (onLeaveHandler) {
          onLeaveHandler();
        }
      }
  );

  return (
    <SubtitleButton
      clickHandler={() => leaveRoom(leaveRoomMutation)}
      className="border-red-primary text-red-primary hover:bg-red-100"
    >
      <MdClose />
      Leave
    </SubtitleButton>
  );
};
