import { Popover, Transition } from '@headlessui/react';
import { MoreVert } from '@mui/icons-material';
import clsx from 'clsx';
import { useState, useMemo, Fragment, useEffect } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { avDeviceIdAtom } from 'renderer/atoms/settings';
import { useMicToggles } from 'renderer/call/components/MicrophoneProvider';
import { useVideoCamToggles } from 'renderer/call/components/videocam/useVideoCamToggles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { logger } from 'renderer/common/LogCreator';

interface DeviceList {
  audio: MediaDeviceInfo[];
  video: MediaDeviceInfo[];
  output: MediaDeviceInfo[];
}

export const useDeviceList = () => {
  const [myDevices, setMyDevices] = useState<DeviceList>({
    audio: [],
    video: [],
    output: [],
  });

  useEffect(() => {
    const fn = async () => {
      const deviceList = await navigator.mediaDevices.enumerateDevices();
      setMyDevices(
        deviceList.reduce(
          (prev: DeviceList, curr) => {
            switch (curr.kind) {
              case 'audioinput':
                prev.audio.push(curr);
                break;
              case 'videoinput':
                prev.video.push(curr);
                break;
              case 'audiooutput':
                prev.output.push(curr);
                break;
              default:
                break;
            }
            return prev;
          },
          {
            audio: [],
            video: [],
            output: [],
          }
        )
      );
    };

    fn();

    navigator.mediaDevices.addEventListener('devicechange', fn);
    return () => navigator.mediaDevices.removeEventListener('devicechange', fn);
  }, []);

  return myDevices;
};

const ChooseAVDevice: React.FC = () => {
  const devices = useDeviceList();

  const [selectedAudioDeviceId, setSelectedAudioDeviceId] = useRecoilState(
    avDeviceIdAtom('audio')
  );
  const resetSelectedAudioDevice = useResetRecoilState(avDeviceIdAtom('audio'));
  const [selectedVideoDeviceId, setSelectedVideoDeviceId] = useRecoilState(
    avDeviceIdAtom('video')
  );
  const resetSelectedVideoDevice = useResetRecoilState(avDeviceIdAtom('video'));
  const [selectedOutputDeviceId, setSelectedOutputDeviceId] = useRecoilState(
    avDeviceIdAtom('output')
  );
  const resetSelectedOutputDevice = useResetRecoilState(
    avDeviceIdAtom('output')
  );

  // TODO: updateOutputDevice somehow.

  const changeDeviceHandler = async (
    device: MediaDeviceInfo,
    deviceType: string
  ) => {
    logger.info('Changing device ', {
      deviceType,
      newDeviceId: device.deviceId,
    });
    if (deviceType === 'audio') {
      setSelectedAudioDeviceId(device.deviceId);
    } else if (deviceType === 'video') {
      setSelectedVideoDeviceId(device.deviceId);
    } else if (deviceType === 'output') {
      setSelectedOutputDeviceId(device.deviceId);
    }
  };

  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);

  return (
    <Popover className="relative">
      {() => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="flex items-center justify-center rounded-full"
          >
            <MoreVert className="!scale-90 text-gray-3 hover:text-gray-4 -ml-2" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute z-40 h-64 p-2 overflow-x-hidden overflow-y-scroll text-xs text-left whitespace-pre-line shadow-thin top-10 bg-white-1 -right-20 w-52 rounded-xl">
              {[
                {
                  data: devices.audio,
                  title: 'Microphone',
                  type: 'audio',
                  selected: selectedAudioDeviceId,
                },
                {
                  data: devices.video,
                  title: 'Video',
                  type: 'video',
                  selected: selectedVideoDeviceId,
                },
                {
                  data: devices.output,
                  title: 'Output',
                  type: 'output',
                  selected: selectedOutputDeviceId,
                },
              ].map((p) => (
                <div
                  className="grid gap-1 p-1 text-left rounded-md text-black-lighter"
                  key={p.type}
                >
                  <div className="text-sm font-medium">Select {p.title}</div>
                  {p.data.map((item) => (
                    <button
                      type="button"
                      key={item.deviceId}
                      onClick={() => changeDeviceHandler(item, p.type)}
                      className="flex items-center p-1 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-2 focus:outline-none"
                    >
                      <div className="flex flex-row">
                        <CheckRoundedIcon
                          className={clsx('!w-4 !h-4', [
                            item.deviceId === p.selected
                              ? 'visible'
                              : 'invisible',
                          ])}
                        />
                        <p className="pl-1 text-left text-gray-900 whitespace-nowrap">
                          {item.label?.replace(/\s*\(.*?\)\s*/g, '')}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              ))}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export const SoundSettingsButton = ({ roomId }: { roomId: string }) => {
  return <ChooseAVDevice />;
};
