import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import { useEffect, useState } from 'react';
import { MdLeakAdd, MdLeakRemove } from 'react-icons/md';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ambientSoundContextAtom,
  AmbientSoundMode,
  ambientSoundModeName,
} from 'renderer/atoms/ambientSound';
import {
  ambientSoundAtom,
  DEFAULT_OVERHEARING_VOLUME,
} from 'renderer/atoms/settings';
import {
  ButtonState,
  buttonStateAtom,
  ButtonType,
} from 'renderer/connection/buttons';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { Tooltip } from 'renderer/shared/Tooltip';
import { AmbientSoundSlider } from './AmbientSoundSlider';
import { AmbientSoundTitle } from './AmbientSoundTitle';

export const AmbientSoundPanel = () => {
  const { mode, tunedInVolume } = useRecoilValue(ambientSoundContextAtom);
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  return (
    <div
      className="flex flex-col items-center w-full p-1 px-2 text-xs rounded-lg cursor-pointer text-black-lightest bg-white-1 h-fit shadow-shadow/soft/card hover:scale-105 hover:bg-neutral-1"
      onClick={() => setSelectedModal(ModalTypes.kConfigureAmbientSound)}
    >
      <span className="flex items-center justify-center gap-1 ">
        <span className="text-sm text-purple-primary">Ambient Sound</span>
        {tunedInVolume}%
      </span>
      <b className="flex items-center gap-1">
        {mode === AmbientSoundMode.NEVER ? <MdLeakRemove /> : <MdLeakAdd />}
        {ambientSoundModeName(mode)}
      </b>
    </div>
  );
};

export const AmbientSoundPanel2 = () => {
  const setOverhearing = useSetRecoilState(
    buttonStateAtom({
      button: ButtonType.OVERHEARING,
    })
  );

  const [ambientSound, setAmbientSound] = useRecoilState(ambientSoundAtom);
  const [overhearingVol, setOverhearingVolume] = useState(ambientSound.vol);
  const MIN = 0;
  const MAX = 30;
  const STEP = 1;

  const toggleMute = () => {
    setAmbientSound((prev) =>
      prev.muted
        ? { muted: false, vol: prev.vol || DEFAULT_OVERHEARING_VOLUME }
        : { ...prev, muted: true }
    );
  };

  useEffect(() => {
    setOverhearingVolume(ambientSound.vol);
  }, [ambientSound]);

  useEffect(() => {
    setOverhearing(ambientSound.muted ? ButtonState.OFF : ButtonState.ON);
  }, [ambientSound.muted, setOverhearing]);

  return (
    <div className="flex flex-col w-full p-1 rounded-lg bg-white-1 h-fit shadow-shadow/soft/card">
      <AmbientSoundTitle muted={ambientSound.muted} />
      <div className="flex flex-row flex-wrap items-center justify-center w-full space-x-3">
        <Tooltip message="Toggle ambient sound">
          <button type="button" onClick={toggleMute}>
            {ambientSound.muted ? (
              <LeakRemoveIcon className="!w-4 !h-4 text-purple-primary" />
            ) : (
              <LeakAddIcon className="!w-4 !h-4 text-purple-primary" />
            )}
          </button>
        </Tooltip>
        <AmbientSoundSlider
          isMuted={ambientSound.muted}
          displayedVolume={overhearingVol}
          min={MIN}
          max={MAX}
          step={STEP}
          onChange={(values) => {
            const vol = values[0];
            setOverhearingVolume(vol);
            setAmbientSound((prev) => ({ muted: vol === 0, vol }));
          }}
        />
      </div>
    </div>
  );
};
