import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { showPageAtom, PageSelector } from 'renderer/atoms/pageselectors';
import { selectedRoomKeyAtom } from 'renderer/atoms/room';
import { selectedTeamKeyAtom } from 'renderer/atoms/team';
import { usePageAttributes } from 'renderer/hooks/usePageAttributes';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { TeamKey, TeamRoomKey } from 'renderer/models/Keys';
import { RoomListTitle, TeamHomeTitle } from './RoomListTitle';
import { UserListPreview } from './UserListPreview';

export const TeamHomePreview: React.FC<
  TeamKey & {
    page: PageSelector.TEAM_ROOMS_OVERVIEW | PageSelector.TEAM_USER_OVERVIEW;
  } & PropsWithChildren
> = ({ teamId, page, children }) => {
  const { selectTeam } = usePageSelector();
  const showTeamPage = useRecoilValue(showPageAtom(page));
  const selectedKey = useRecoilValue(selectedTeamKeyAtom);
  const isSelected = showTeamPage && selectedKey === teamId;

  const { title, icon } = usePageAttributes(page, null);

  return (
    <div className="flex flex-col rounded-md">
      <div
        className={clsx(
          'relative pl-[10px] w-full h-full py-1 pr-1 rounded-md hover:text-gray-5 text-gray-4/90',
          [isSelected && 'bg-blue-lightest-2']
        )}
      >
        {!isSelected && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            className="absolute inset-0 z-10 flex flex-row items-center rounded-md"
            onClick={() => {
              selectTeam({ teamId }, page);
            }}
          />
        )}
        <TeamHomeTitle selected={isSelected} title={title}>
          {children}
          {icon}
        </TeamHomeTitle>
      </div>
    </div>
  );
};

export const ActiveRoomPreview: React.FC<TeamRoomKey & { isDm?: boolean }> = ({
  teamId,
  roomId,
  isDm = false,
}) => {
  const { selectRoom } = usePageSelector();
  const showPage = useRecoilValue(showPageAtom(PageSelector.TEAM_ROOM));
  const selectedKey = useRecoilValue(selectedRoomKeyAtom);
  const isSelected = showPage && selectedKey === roomId;

  return (
    <div className="flex flex-col rounded-md">
      <div
        className={clsx(
          'relative pl-[10px] w-full h-full py-1 pr-1 rounded-md hover:text-gray-5 text-gray-4/90',
          [isSelected && 'bg-blue-lightest-2']
        )}
      >
        {!isSelected && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            type="button"
            className="absolute inset-0 z-10 flex flex-row items-center w-full h-full rounded-md"
            onClick={() => selectRoom({ teamId, roomId })}
          />
        )}
        <RoomListTitle roomId={roomId} selected={isSelected} isDm={isDm} />
        {!isDm && (
          <div className="flex flex-row items-center space-x-1 bg-inherit">
            <UserListPreview roomId={roomId} />
          </div>
        )}
      </div>
    </div>
  );
};
