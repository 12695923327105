import { ReactNode } from 'react';
import { atom, selector, useRecoilCallback } from 'recoil';
import { selectedRoomAtom, roomAtom } from 'renderer/atoms/room';
import { selectedTeamAtom } from 'renderer/atoms/team';

export enum ModalTypes {
  kScreenshareMenu,
  kScreenshareView,
  kRenameRoom,
  kLeaveRoom,
  kInviteModal,
  kAddTeamMenu,
  kCreateTeam,
  kBrowseOrCreateRoom,
  kCreateRoom,
  kTalkToBusyUser,
  kCreateOrUpdateEvent,
  kListEventsForRoom,
  kTeamInviteModal,
  kNone,
  kLinkCalendar,
  kConfigureAmbientSound,
}

const modalErrorMessageAtom = atom<string>({
  key: 'modalErrorMessage',
  default: '',
});

export const screenShareModalRoomIdAtom = atom<string | null>({
  key: 'screenShareModalRoomIdAtom',
  default: null,
});

export const selectedModalAtom = atom<ModalTypes>({
  key: 'selectedModal',
  default: ModalTypes.kNone,
});

export const modalTitleAtom = selector<ReactNode>({
  key: 'modalTitle',
  get: ({ get }) => {
    const selected = get(selectedModalAtom);
    switch (selected) {
      case ModalTypes.kLeaveRoom: {
        const { name: selectedRoom } = get(selectedRoomAtom);
        return (
          <>
            Leaving <span className="underline">{selectedRoom}</span>
          </>
        );
      }
      case ModalTypes.kRenameRoom: {
        const { name: selectedRoom } = get(selectedRoomAtom);
        return (
          <>
            Rename <span className="text-blue-900">{selectedRoom}</span>
          </>
        );
      }
      case ModalTypes.kTeamInviteModal: {
        const { name: selectedTeam } = get(selectedTeamAtom);
        return (
          <>
            <span className="!font-semibold text-black-lighter">
              Invite teammates to{' '}
            </span>
            <br />
            <span className="!font-semibold capitalize text-blue-900">
              {selectedTeam}
            </span>
          </>
        );
      }
      case ModalTypes.kInviteModal: {
        const { name: selectedRoom } = get(selectedRoomAtom);
        return (
          <>
            <span className="!font-semibold text-black-lighter">
              Manage members in{' '}
            </span>
            <br />
            <span className="!font-semibold capitalize text-blue-900">
              {selectedRoom}
            </span>
          </>
        );
      }
      case ModalTypes.kScreenshareMenu: {
        const roomId = get(screenShareModalRoomIdAtom);
        if (!roomId) return null;
        const { name: selectedRoom } = get(roomAtom(roomId));
        return (
          <>
            <span className="!font-semibold text-black-lighter">
              Share your screen with {selectedRoom}
            </span>
          </>
        );
      }
      case ModalTypes.kCreateTeam: {
        return (
          <>
            <span className="!font-semibold text-black-lighter">
              Create a team
            </span>
          </>
        );
      }
      case ModalTypes.kLinkCalendar: {
        return (
          <div className="w-full text-2xl font-semibold text-center text-black-lighter">
            Add a Calendar
          </div>
        );
      }
      case ModalTypes.kConfigureAmbientSound:
        return (
          <div className="w-full text-xl text-black-lighter">Ambient Sound</div>
        );
      default:
        return null;
    }
  },
});

export const useModalErrors = () => {
  const setModalError = useRecoilCallback(
    ({ set }) =>
      async (message: string) => {
        set(modalErrorMessageAtom, message);
        setTimeout(() => {
          set(modalErrorMessageAtom, (prev) => (prev === message ? '' : prev));
        }, 3_000);
      }
  );
  const errorAtom = modalErrorMessageAtom;
  return { setModalError, errorAtom };
};
