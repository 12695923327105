import { useEffect, useMemo, useState } from 'react';
import {
  atomFamily,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
} from 'recoil';
import { convoAmbientSoundModeAtom } from 'renderer/atoms/ambientSound';
import {
  MaxRecentLeftConvoLength,
  recentlyLeftConvoAtom,
} from 'renderer/atoms/conversation';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import {
  isMicActiveAtom,
  roomTeamIdAtom,
  roomUserAtom,
} from 'renderer/atoms/room';
import {
  activeEventsAtom,
  nextEventInstanceAtom,
} from 'renderer/atoms/userUtils';
import {
  localMicActiveAtom,
  useMicToggles,
} from 'renderer/call/components/MicrophoneProvider';
import { useSceenshareToggles } from 'renderer/call/components/ScreenShareProvider';
import { useVideoCamToggles } from 'renderer/call/components/videocam/useVideoCamToggles';
import { trpc } from 'renderer/common/client/trpc';
import { availableStatusAtom } from 'renderer/connection/state';
import {
  selectedUserStatusButtonAtom,
  UserStatus,
} from 'renderer/connection/userStatusRadioGroup';
import { msTillLeaveConversation } from 'renderer/constants';
import { ConvoKey, RoomKey, TeamRoomKey, UserKey } from 'renderer/models/Keys';
import { useModalErrors } from 'renderer/shared/Modals/atoms';

export const leaveConvoTimeAtom = atomFamily<number, RoomKey>({
  key: 'leaveConvoTime',
  default: -1,
});

export const joinConvoLoadingAtom = atomFamily<boolean, UserKey | ConvoKey>({
  key: 'joinConvoLoadingAtom',
  default: false,
});

export const lastLeftConversationTimeAtom = atomFamily<Date, ConvoKey>({
  key: 'lastLeftConversationTimeAtom',
  default: new Date(0),
});

export const hasSelfUserParticipatedInConvoAtom = atomFamily<boolean, ConvoKey>(
  {
    key: 'hasUserAnsweredConvoRequest',
    default: false,
  }
);

export const useConvoHooks = () => {
  const { setModalError } = useModalErrors();
  const userId = useRecoilValue(selfUserIdAtom);
  const convoLeaveMutation = trpc.useMutation('convos.leave');
  const convoJoinMutation = trpc.useMutation('convos.join', {
    onError: (err) => {
      setModalError(err.message);
    },
  });
  const { turnOffTrack: turnOffScreen } = useSceenshareToggles();
  const { turnOffTrack: turnOffVid } = useVideoCamToggles();
  const { toggleMic } = useMicToggles();

  // TODO: We'd like to automatically disable screensharing and video here too.
  // const { turnOffTrack: turnOffScreen } = useSceenshareToggles();
  // const { turnOffTrack: turnOffCam } = useVideoCamToggles();

  const joinConvo = useRecoilCallback(
    ({ snapshot, set, reset }) =>
      async (param: TeamRoomKey, query: UserKey | ConvoKey) => {
        const loader = joinConvoLoadingAtom(query);
        if (await snapshot.getPromise(loader)) {
          throw new Error('Already in progress');
        }
        set(loader, true);
        try {
          // Go online if not already online.
          const online = await snapshot.getPromise(availableStatusAtom);
          if (!online) {
            set(selectedUserStatusButtonAtom, UserStatus.Listening);
          }

          // Join the conversation.
          await convoJoinMutation.mutateAsync({
            ...param,
            target: query,
          });

          // Turn on the mic after joining.
          await toggleMic({ roomId: param.roomId, forceOn: true });
          set(loader, false);

          if ('convoId' in query) {
            // Whenever a user joins a conversation, we should reconfigure ambient sound for that thing.
            reset(convoAmbientSoundModeAtom(query));
          }
        } catch (err) {
          set(loader, false);
          throw err;
        }
      }
  );

  const leaveConvo = useRecoilCallback(
    ({ snapshot, set }) =>
      async (param: TeamRoomKey, convoId?: string) => {
        const {
          convo: { convoId: currConvoId },
        } = await snapshot.getPromise(
          roomUserAtom({ roomId: param.roomId, userId })
        );
        if (!currConvoId) return;
        if (convoId && convoId !== currConvoId) return;

        // Turn off screenshare
        await Promise.all([
          await turnOffScreen({ roomId: param.roomId }),
          await turnOffVid({ roomId: param.roomId }),
          await toggleMic({
            roomId: param.roomId,
            forceOff: true,
            // leave api already turns off devices. Enabling sync would cause 2 requests to go
            // out, which increases latency by like 1-2 seconds due to some transaction conflicts
            syncWithBackend: false,
          }),
        ]);

        // Turn off video

        // Turn off the mic first.

        // Leave the conversation.
        await convoLeaveMutation.mutateAsync(param);
        set(lastLeftConversationTimeAtom({ convoId: currConvoId }), new Date());
        set(recentlyLeftConvoAtom, (prev) => {
          return [currConvoId, ...prev.slice(0, MaxRecentLeftConvoLength - 1)];
        });
      }
  );

  // indicate that the selfUser has answered a call (like they unmuted their mic)
  const setParticipatedInConvo = useRecoilCallback(
    ({ set }) =>
      async (convoId: string) => {
        set(hasSelfUserParticipatedInConvoAtom({ convoId }), true);
      }
  );

  return { leaveConvo, joinConvo, setParticipatedInConvo };
};

/**
 *  todo: a bubble is active if anyone has their mic on, including yourself.
 *  -- make the atom count yourself as part of the things to monitor
 *  -- mute the mic if conversation expires
 *  -- unmute myself if i click on someone to join their table
 */
export const useConversationExpirationMonitor = (roomId: string) => {
  const userId = useRecoilValue(selfUserIdAtom);
  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const roomUser = useRecoilValue(roomUserAtom({ roomId, userId }));
  const userHasConvoId = useMemo(
    () => roomUser.convo.convoId !== undefined,
    [roomUser.convo.convoId]
  );
  const userInConvo = useMemo(
    () => roomUser.convo.engaged,
    [roomUser.convo.engaged]
  );
  const { leaveConvo, setParticipatedInConvo } = useConvoHooks();
  const [leavingConvoTime, setLeavingConvoTime] = useRecoilState(
    leaveConvoTimeAtom({ roomId })
  );
  const isMicActive = useRecoilValue(localMicActiveAtom({ roomId }));
  const resetLeavingConvoTime = useResetRecoilState(
    leaveConvoTimeAtom({ roomId })
  );
  const eventDetails = useRecoilValue(
    activeEventsAtom({
      roomId,
    })
  );
  const isActiveEvent = useMemo(
    () =>
      !!eventDetails?.find(({ eventId }) => eventId === roomUser.convo.convoId),
    [eventDetails, roomUser.convo.convoId]
  );
  useEffect(() => {
    if (!roomUser.convo.convoId || !isMicActive) {
      return;
    }

    setParticipatedInConvo(roomUser.convo.convoId);
  }, [roomUser, isMicActive]);

  const [startLeaveProcess, setStartLeaveProcess] = useState(false);

  useEffect(() => {
    if (leavingConvoTime > 0) {
      const tm = setTimeout(
        () =>
          setLeavingConvoTime((prev) => prev - 250 / msTillLeaveConversation),
        250
      );
      return () => clearTimeout(tm);
    }
  }, [leavingConvoTime, setLeavingConvoTime]);

  useEffect(() => {
    if (startLeaveProcess) {
      const { convoId } = roomUser.convo;
      setLeavingConvoTime(1);
      const tm = setTimeout(
        () => leaveConvo({ teamId, roomId }, convoId),
        msTillLeaveConversation
      );
      return () => {
        resetLeavingConvoTime();
        clearTimeout(tm);
      };
    }
    // We don't want other dependencies here specifically.
    // This will automatically reset whenever the below effect resets.
  }, [
    roomId,
    roomUser.convo.convoId,
    setLeavingConvoTime,
    startLeaveProcess,
    teamId,
  ]);

  // We use a delay to even begin the leave process just in case there's a UI lag.
  useEffect(() => {
    if (!userInConvo && userHasConvoId && !isActiveEvent) {
      const tm = setTimeout(() => setStartLeaveProcess(true), 3_000);
      return () => clearTimeout(tm);
    }
    setStartLeaveProcess(false);
  }, [userHasConvoId, userInConvo, isActiveEvent]);
};
