/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { Suspense, useMemo, useRef } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { realtimeDbConnectionStatusAtom } from 'renderer/atoms/connectionStatus';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { requireAuth } from 'renderer/common/components/hoc/requireAuth';
import { availableStatusAtom } from 'renderer/connection/state';
import { useRealtimeDbConnection } from 'renderer/hooks/useRealtimeDbConnection';
import { SidePanel } from 'renderer/pages/dashboard/components/SidePanel';
import { RoomsProvider } from 'renderer/pages/dashboard/providers/RoomsProvider';
import SettingsContainer from 'renderer/pages/settings/SettingsContainer';
import MiniGloo from 'renderer/shared/MiniGloo';
import { Modal } from 'renderer/shared/Modals/Modal';
import { Spinner } from 'renderer/shared/Spinner';
import { ChildProps } from 'types/react';
import { MainView } from './components/MainView';
import { MachineStateProvider } from './providers/MachineStateProvider';

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button type="button" onClick={resetErrorBoundary}>
        Try again
      </button>
    </div>
  );
}

const Dashboard = () => {
  return (
    <>
      <Suspense
        fallback={
          <div className="items-center justify-center w-full h-full">
            <ClipLoader color="grey" />
          </div>
        }
      >
        <RealtimeDbProvider>
          <MachineStateProvider>
            <RoomsProvider />
            <React.StrictMode>
              <Routes>
                <Route path="/" element={<DashboardContents />} />
                <Route path="/settings" element={<SettingsContainer />} />
              </Routes>
            </React.StrictMode>
          </MachineStateProvider>
        </RealtimeDbProvider>
      </Suspense>
    </>
  );
};

const RealtimeDbProvider = ({ children }: ChildProps) => {
  useRealtimeDbConnection();
  const isRealtimeDbConnected = useRecoilValue(realtimeDbConnectionStatusAtom);
  if (!isRealtimeDbConnected) {
    return <>Disconnected!</>;
  }
  return <>{children}</>;
};

const DashboardContents = () => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div
      className="flex flex-col w-full h-full pb-0 space-y-3 overflow-visible"
      ref={ref}
    >
      <div className="flex flex-row w-full h-full">
        <SidePanel />
        <MainView />
        <Modal />
        <MiniGloo appRef={ref} />
      </div>
    </div>
  );
};

export default requireAuth(Dashboard);
