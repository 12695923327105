import { Add } from '@mui/icons-material';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { sortedTeamListAtom } from 'renderer/atoms/team';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { NotificationBell } from '../../MainView/NotificationsPage/NotificationBell';
import { TeamSectionPreview } from './TeamSectionPreview';

export const RoomListPanel = () => {
  const teamList = useRecoilValue(sortedTeamListAtom);

  const openAddTeamModal = useRecoilCallback(({ set }) => async () => {
    set(selectedModalAtom, ModalTypes.kAddTeamMenu);
  });

  return (
    <>
      <div className="flex flex-col mt-2 text-left overflow-clip grow">
        {/* <div className="text-sm lg:text-lg text-gray-5">ACTIVE ROOMS</div> */}
        {teamList.length > 0 && (
          <div className="sticky flex flex-row items-center justify-between w-full px-2">
            <button
              type="button"
              onClick={openAddTeamModal}
              className="flex items-center justify-center p-2 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 focus:outline-none"
            >
              <div className="flex items-center w-full h-full gap-1 hover:scale-105">
                <Add className="!w-auto !h-[16px]" />
                <div className="flex items-center h-full pt-[2px] text-base font-normal text-black-lightest hover:text-black-lighter whitespace-nowrap">
                  Add a team
                </div>
              </div>
            </button>
            <NotificationBell />
          </div>
        )}
        <div className="flex flex-col gap-2 overflow-y-auto">
          {teamList.map((t) => {
            return <TeamSectionPreview teamId={t} key={t} />;
          })}
        </div>
      </div>
    </>
  );
};
