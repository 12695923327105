/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
import AgoraRTC, {
  ILocalAudioTrack,
  IMicrophoneAudioTrack,
} from 'agora-rtc-sdk-ng';
import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback,
  useRecoilValue,
} from 'recoil';
import {
  roomAtom,
  roomTeamIdAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import {
  buttonActive,
  buttonPressedTimeAtom,
  ButtonState,
  buttonStateAtom,
  ButtonType,
} from 'renderer/connection/buttons';
import {
  lastSelfUserConvoTimeAtom,
  lastUserSpeakTimeAtom,
  userSpeakingAtom,
  userSpeakingVolumeAtom,
} from 'renderer/connection/voiceCallState';
import { minVolumeTrigger, secondsUntilMuted } from 'renderer/constants';
import { RoomKey } from 'renderer/models/Keys';

import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import { avDeviceIdAtom } from 'renderer/atoms/settings';
import { trpcAtom } from 'renderer/common/components/TRPCReactQuery';
import { buttonDebounce } from 'renderer/common/debounce';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { usePrevious } from 'renderer/hooks/usePrevious';

const logger = LogCreator(LoggerNames.MIC);

export const localMicTrackAtom = atom<{
  track: IMicrophoneAudioTrack;
  roomId: string;
} | null>({
  key: 'localTrack',
  default: null,
  // So we can close the track.
  dangerouslyAllowMutability: true,
});

export const localMicActiveAtom = selectorFamily<boolean, { roomId: string }>({
  key: 'micActive',
  get:
    ({ roomId }) =>
    ({ get }) => {
      return get(localMicTrackAtom)?.roomId === roomId;
    },
});

export const micTrackAtom = selector<ILocalAudioTrack | null>({
  key: 'micTrack',
  get: ({ get }) => get(localMicTrackAtom)?.track || null,
});

export const micRoomNameAtom = selector<string | null>({
  key: 'micRoomName',
  get: ({ get }) => {
    const roomId = get(micActiveRoomIdAtom);
    if (!roomId) return null;
    return get(roomAtom(roomId)).name;
  },
});

export const micActiveRoomIdAtom = selector<string | undefined>({
  key: 'micActiveRoomId',
  get: ({ get }) => {
    const roomId = get(localMicTrackAtom)?.roomId;
    return roomId || undefined;
  },
});

export const useMicToggles = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdatingDevice, setIsUpdatingDevice] = useState(false);
  const [lastSetDeviceId, setLastSetDeviceId] = useState('default');

  // TODO: What we need here is a useEffect that syncs state properly.
  // see the useClient in ChatContainer.tsx, where we can await
  // the previous invocation (or cancel it).
  // it is dangerous right now to just ignore a command.

  const swapTrack = useRecoilCallback(
    ({ set, snapshot }) =>
      async (
        targetRoomId: string,
        { useMegaPhone }: { useMegaPhone: boolean }
      ) => {
        if (isLoading) {
          throw new Error(
            "MicrophoneProvider: Couldn't swap track, try again later"
          );
        }
        setIsLoading(true);
        try {
          const response = await snapshot.getPromise(localMicTrackAtom);
          logger.debug(
            'MicrophoneProvider: Swapping track',
            response,
            targetRoomId
          );
          if (!response)
            throw new Error('MicrophoneProvider: Must turn on track.');

          // Close the previous track.
          const { roomId } = response;
          // note we don't close the track because it'll be reused by the target room.
          // If we do close it here we'll have an error from Agora when it tries to
          // reuse it. Swapping is diff from turning off/on since swapping doesn't close +
          // recreate the mic track.
          const trpcClient = await snapshot.getPromise(trpcAtom);
          const now = new Date().getTime();
          const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
          const targetTeamId = await snapshot.getPromise(
            roomTeamIdAtom({ roomId: targetRoomId })
          );
          await Promise.all([
            // trpcClient.mutation('users.status.device', {
            //   roomId,
            //   teamId,
            //   mode: 'off',
            //   device: 'mic',
            // }),
            trpcClient.mutation('users.status.device', {
              roomId: targetRoomId,
              teamId: targetTeamId,
              mode: useMegaPhone ? 'broadcast' : 'on',
              device: 'mic',
            }),
            set(localMicTrackAtom, { ...response, roomId: targetRoomId }),
            // Explicitly set the megaphone status to match the desired value.
            set(
              buttonStateAtom({ button: ButtonType.MEGAPHONE }),
              useMegaPhone ? ButtonState.ON : ButtonState.OFF
            ),
          ]);
        } finally {
          setIsLoading(false);
        }
      }
  );

  const turnOffTrack = useRecoilCallback(
    ({ set, snapshot }) =>
      async (syncWithBackend = true) => {
        if (isLoading) {
          throw new Error("Couldn't turn off track, try again later");
        }
        setIsLoading(true);
        try {
          const response = await snapshot.getPromise(localMicTrackAtom);
          logger.debug('MicrophoneProvider: Turning off room', response);
          if (!response) return;
          const { track, roomId } = response;
          track.close();
          const { userId } = await snapshot.getPromise(selfGlooUserAtom);
          set(localMicTrackAtom, null);
          set(userSpeakingAtom({ roomId, userId }), false);
          set(buttonStateAtom({ button: ButtonType.MIC }), ButtonState.OFF);
          set(
            buttonStateAtom({ button: ButtonType.MEGAPHONE }),
            ButtonState.OFF
          );

          // TODO: add error handling
          const trpcClient = await snapshot.getPromise(trpcAtom);
          const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));
          const now = new Date().getTime();
          if (syncWithBackend) {
            await trpcClient.mutation('users.status.device', {
              roomId,
              teamId,
              mode: 'off',
              device: 'mic',
            });
          }
        } finally {
          setIsLoading(false);
        }
      },
    [isLoading]
  );

  const turnOnTrack = useRecoilCallback(
    ({ snapshot, set }) =>
      async ({ useMegaPhone, roomId }: { useMegaPhone: boolean } & RoomKey) => {
        if (isLoading) {
          throw new Error("Couldn't swap track, try again later");
        }
        setIsLoading(true);
        let track;
        try {
          logger.debug('MicrophoneProvider: turnOnTrack');
          const localTrack = await snapshot.getPromise(localMicTrackAtom);
          if (localTrack) throw new Error('Should use swap track');

          track = {
            track: await AgoraRTC.createMicrophoneAudioTrack(),
            roomId,
          };
          logger.info('created track', {
            id: track.track.getTrackId(),
            label: track.track.getTrackLabel(),
            mediaStreamId: track.track.getMediaStreamTrack().id,
          });
          set(localMicTrackAtom, track);
          const updateDevicePromise = updateDevice(track.track);

          set(buttonStateAtom({ button: ButtonType.MIC }), ButtonState.ON);

          if (useMegaPhone) {
            set(
              buttonStateAtom({ button: ButtonType.MEGAPHONE }),
              ButtonState.ON
            );
          }
          const [trpcClient, teamId] = await Promise.all([
            snapshot.getPromise(trpcAtom),
            snapshot.getPromise(roomTeamIdAtom({ roomId })),
          ]);

          // pass the track directly since set(localTrack..) is not guaranteed to be set atomically
          // and read properly in updateDevice(..)
          await Promise.all([
            await trpcClient.mutation('users.status.device', {
              roomId,
              teamId,
              mode: useMegaPhone ? 'broadcast' : 'on',
              device: 'mic',
            }),
            updateDevicePromise,
          ]);
        } catch (e) {
          logger.error('MicrophoneProvider: Error turning on mic', e);
          track?.track.close();
          set(localMicTrackAtom, null);
          set(buttonStateAtom({ button: ButtonType.MIC }), ButtonState.OFF);
        } finally {
          setIsLoading(false);
        }
      },
    [isLoading]
  );

  // Used for upgrading the mic to broadcast mode.
  const toggleMegaPhoneState = useRecoilCallback(
    ({ snapshot, set }) =>
      async (useMegaPhone: boolean) => {
        logger.debug('MicrophoneProvider: toggleMegaPhoneState');
        const localTrack = await snapshot.getPromise(localMicTrackAtom);
        if (!localTrack)
          throw new Error(
            'MicrophoneProvider: Need to turn on the track first.'
          );
        const { roomId } = localTrack;

        set(
          buttonStateAtom({ button: ButtonType.MEGAPHONE }),
          useMegaPhone ? ButtonState.ON : ButtonState.OFF
        );
        const trpcClient = await snapshot.getPromise(trpcAtom);
        const now = new Date().getTime();
        const teamId = await snapshot.getPromise(roomTeamIdAtom({ roomId }));

        await trpcClient.mutation('users.status.device', {
          roomId,
          teamId,
          mode: useMegaPhone ? 'broadcast' : 'on',
          device: 'mic',
        });
      }
  );

  // Note! if we call this in parallel we can get a microphone leak (not closed properly)
  const updateDevice = useRecoilCallback(
    ({ snapshot, set }) =>
      async (track?: IMicrophoneAudioTrack) => {
        setIsUpdatingDevice(true);
        try {
          const deviceId = await snapshot.getPromise(avDeviceIdAtom('audio'));
          const localTrack = await snapshot.getPromise(localMicTrackAtom);
          logger.info('MicrophoneProvider: updateDevice', {
            deviceId,
            localTrack,
            track,
          });

          const trackToModify = track || localTrack?.track;
          if (!trackToModify) {
            return;
          }
          let actualDeviceId = deviceId;
          // We do this cause agora wont actually switch the mic unless you give it an actual
          // deviceId that is not the "default" string, so we have to find the actual deviceId
          // by linking with groupId.
          if (actualDeviceId === 'default') {
            const mics = await AgoraRTC.getMicrophones();
            const defaultDevice = mics.find((m) => m.deviceId === 'default');
            const actualDevice = mics.find(
              (m) =>
                m.deviceId !== 'default' && m.groupId === defaultDevice?.groupId
            );
            actualDeviceId = actualDevice?.deviceId ?? 'default';
          }

          logger.info('MicrophoneProvider: updateDevice changing device', {
            deviceId,
            actualDeviceId,
          });
          await trackToModify.setDevice(actualDeviceId);
          setLastSetDeviceId(actualDeviceId);
        } catch (error: any) {
          console.log('Error setting device', error);
        } finally {
          setIsUpdatingDevice(false);
        }
      },
    [isUpdatingDevice, lastSetDeviceId]
  );

  const toggleMic = useRecoilCallback(
    ({ snapshot }) =>
      buttonDebounce(
        async ({
          roomId,
          forceOff,
          forceOn,
          syncWithBackend,
        }: {
          roomId: string;
          forceOff?: boolean;
          forceOn?: boolean;
          syncWithBackend?: boolean;
        }) => {
          const button = await snapshot.getPromise(
            buttonActive({ button: ButtonType.MIC })
          );
          const micActiveInRoom = await snapshot.getPromise(
            localMicActiveAtom({ roomId })
          );
          if (!micActiveInRoom && forceOff) {
            // Mic is already off for this room.
            return;
          }
          if (micActiveInRoom && forceOn) {
            // Mic is already on for this room.
            return;
          }
          if (button) {
            if (micActiveInRoom) {
              turnOffTrack(syncWithBackend);
            } else {
              logger.info('MicrophoneProvider: Swapping track');
              swapTrack(roomId, { useMegaPhone: false });
            }
          } else {
            turnOnTrack({ useMegaPhone: false, roomId });
          }
        }
      ),
    [turnOffTrack, turnOnTrack, swapTrack]
  );

  return {
    turnOffTrack,
    turnOnTrack,
    swapTrack,
    toggleMegaPhoneState,
    toggleMic,
    updateDevice,
    isLoading,
    isUpdatingDevice,
  };
};

/** Responsible for turning off mic in the following scenarios:
 *   1. User goes offline
 *
 * Additionally monitors if we should or should not be publishing the microphone track.
 *   1. If at least 2 users (including yourself) are online at the table, publish the track.
 */
const MonitorMic: React.FC<RoomKey> = ({ roomId }) => {
  const { userId, device, convo } = useRecoilValue(selfGlooUserAtom);
  const { turnOffTrack } = useMicToggles();
  const inConvo = useMemo(
    () => !!convo.find((c) => c.roomId === roomId),
    [convo, roomId]
  );
  const previousRoomId = usePrevious(device?.mic?.roomId);

  const setRoomUserVolume = useRecoilCallback(
    ({ set, snapshot }) =>
      async () => {
        const response = await snapshot.getPromise(localMicTrackAtom);
        const volume = response?.track.getVolumeLevel() || 0;
        const speaking = volume > minVolumeTrigger;
        set(userSpeakingAtom({ roomId, userId }), speaking);
        set(userSpeakingVolumeAtom({ roomId, userId }), volume);
        if (speaking) {
          set(lastUserSpeakTimeAtom({ roomId, userId }), new Date());
        }
      }
  );

  const maybeTurnOffTrack = useRecoilCallback(({ snapshot }) => async () => {
    const lastActiveTime = await snapshot.getPromise(
      lastSelfUserConvoTimeAtom({ roomId })
    );
    const micActiveTime = await snapshot.getPromise(
      buttonPressedTimeAtom({ button: ButtonType.MIC })
    );
    const screenShareActive = await snapshot.getPromise(
      buttonActive({ button: ButtonType.SCREENSHARE })
    );

    const now = new Date();
    // Don't turn off if screenshare is on.
    if (screenShareActive) return;
    // We should allow the mic to be unmuted for upto some time.
    if (differenceInSeconds(now, micActiveTime) <= secondsUntilMuted) return;
    // We should allow no conversations upto some time.
    if (differenceInSeconds(now, lastActiveTime) <= secondsUntilMuted) return;

    // TODO: We should really put a progress bar around the mic and show it'll turn off in 5 seconds
    // due to no activity.
    await turnOffTrack();
  });

  // Monitor our volume while talking.
  useEffect(() => {
    const interval = setInterval(() => setRoomUserVolume(), 250);
    return () => clearInterval(interval);
  }, [setRoomUserVolume]);

  // If only we are online after X seconds, turn off the mic.
  useEffect(() => {
    // Check for this every 15 seconds.
    const micOffDelayMs = 15_000;
    const ret = setInterval(maybeTurnOffTrack, micOffDelayMs);
    return () => clearInterval(ret);
  }, [maybeTurnOffTrack]);

  useEffect(() => {
    if (
      previousRoomId === roomId &&
      device?.mic?.roomId !== roomId &&
      !inConvo
    ) {
      turnOffTrack();
    }
  }, [device?.mic?.roomId, previousRoomId, roomId, turnOffTrack, inConvo]);

  return <></>;
};

export const MicrophoneProvider: React.FC = () => {
  const track = useRecoilValue(localMicTrackAtom);
  const { turnOffTrack } = useMicToggles();

  // Turn off the track when the copmonent dismounts.
  useEffect(() => {
    return () => {
      turnOffTrack();
    };
    // not filling in deps since it causes mic to never turn on
  }, []);

  return <>{track && <MonitorMic roomId={track.roomId} />}</>;
};
