import ChevronRight from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { ClassNameProps } from 'types/react';

export interface ButtonProps {
  clickHandler: () => void;
  // the actual button text
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  enableChevron?: boolean;
  circular?: boolean;
}

export const SimpleButton: React.FC<ButtonProps> = ({
  className = '',
  clickHandler,
  children,
  disabled = false,
  enableChevron,
  circular,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      onClick={(event) => {
        event.stopPropagation();
        clickHandler();
      }}
      className={
        className ||
        clsx(
          'flex h-fit  text-white-1 w-auto',
          disabled ? 'bg-gray-4' : 'bg-black-primary hover:bg-black-lighter/80',
          circular ? 'rounded-full' : 'rounded-md px-3 py-1'
        )
      }
      type="button"
    >
      {enableChevron ? (
        <div className="flex flex-row items-center">
          {children}
          <ChevronRight fontSize="inherit" />
        </div>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

export const SubtitleButton: React.FC<
  { clickHandler: () => void; disabled?: boolean } & PropsWithChildren &
    ClassNameProps
> = ({ clickHandler, children, className, disabled }) => {
  return (
    <SimpleButton
      className={clsx(
        'flex w-fit px-2 duration-300 transform border rounded-md hover:scale-105 text-sm',
        className ?? 'border-blue-primary text-blue-primary hover:bg-blue-100'
      )}
      clickHandler={clickHandler}
      disabled={disabled}
    >
      <div className="flex flex-row items-center gap-1">{children}</div>
    </SimpleButton>
  );
};
