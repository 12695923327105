import clsx from 'clsx';
import { useEffect } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { userNudgedBySelfAtom, userNudgedMeAtom } from 'renderer/atoms/nudge';
import { roomTeamIdAtom, roomUserAtom } from 'renderer/atoms/room';
import { trpc } from 'renderer/common/client/trpc';
import { useConvoHooks } from 'renderer/hooks/useConversationExpirationMonitor';
import { RoomUserKey } from 'renderer/models/Keys';
import { SimpleButton } from '../SimpleButton';
import { GlooUser } from '../user/GlooUser';
import { ModalTypes, selectedModalAtom, useModalErrors } from './atoms';

export const userIdPreviewAtom = atom<RoomUserKey | null>({
  key: 'userIdPreviewAtom',
  default: null,
});

export const TalkToBusyUserModal = () => {
  const { setModalError } = useModalErrors();

  const sendNudgeMutation = trpc.useMutation(['users.nudge.deliver'], {
    onError: (error) => {
      setModalError(error.message);
    },
  });
  const completeNudgeMutation = trpc.useMutation(['users.nudge.complete'], {
    onError: (error) => {
      setModalError(error.message);
    },
  });
  const cancelNudgeMutation = trpc.useMutation(['users.nudge.remove'], {
    onError: (error) => {
      setModalError(error.message);
    },
  });

  const { joinConvo } = useConvoHooks();
  const { userId: selectedUserId, roomId } = useRecoilValue(
    userIdPreviewAtom
  ) ?? { userId: 'NONE', roomId: 'NONE' };
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  const teamId = useRecoilValue(roomTeamIdAtom({ roomId }));
  const {
    profile,
    online: isUserOnline,
    busy: isUserBusy,
    convo: { withPrimaryUser: convoWithMe },
  } = useRecoilValue(roomUserAtom({ roomId, userId: selectedUserId }));
  const didINudgeUser = useRecoilValue(
    userNudgedBySelfAtom({ userId: selectedUserId, roomId })
  );
  const didUserNudgedMe = useRecoilValue(
    userNudgedMeAtom({ userId: selectedUserId, roomId })
  );

  useEffect(() => {
    if (convoWithMe) {
      setSelectedModal(ModalTypes.kNone);
    }
  }, [convoWithMe]);

  // eslint-disable-next-line no-nested-ternary
  const message = isUserOnline
    ? isUserBusy
      ? 'may be busy at the moment.'
      : 'is available now!'
    : 'is unavailable at the moment.';

  return (
    <div className="flex flex-col items-center justify-center w-full gap-3">
      <GlooUser roomId={roomId} userId={selectedUserId} />
      {didUserNudgedMe && <span className="text-xs">They left a nudge!</span>}
      <div className="px-3 font-normal text-center">
        <b>{profile.displayName}</b> {message}
      </div>

      <div className="flex flex-col items-center gap-1 text-center">
        <SimpleButton
          className={clsx(
            'flex w-fit px-2 py-1 duration-300 transform border rounded-md hover:scale-105 text-base',
            'border-blue-primary text-blue-primary hover:bg-blue-100'
          )}
          clickHandler={() => {
            const f = async () => {
              if (didINudgeUser) {
                await cancelNudgeMutation.mutateAsync({
                  targetUserId: selectedUserId,
                  roomId,
                });
              } else {
                if (didUserNudgedMe) {
                  await completeNudgeMutation.mutateAsync({
                    senderUserId: selectedUserId,
                    roomId,
                  });
                }
                await sendNudgeMutation.mutateAsync({
                  targetUserId: selectedUserId,
                  teamId,
                  roomId,
                });
              }
            };
            f().catch((e) => console.log('NUDGE ERR: ', e));
          }}
        >
          <div className="flex flex-row items-center gap-0">
            {didINudgeUser ? 'Clear your nudge' : 'Leave a nudge'}
          </div>
        </SimpleButton>

        <span className="max-w-[15rem] text-xs text-black-lightest">
          Nudges are private async notifications which let{' '}
          <b className="whitespace-nowrap">{profile.displayName}</b> know you
          wanted to talk.
        </span>
      </div>

      {isUserOnline && (
        <div className="flex flex-col items-center gap-1 text-center">
          <SimpleButton
            className={clsx(
              'flex w-fit px-2 py-1 duration-300 transform border rounded-md hover:scale-105',
              isUserBusy
                ? 'text-sm border-red-500 text-red-500 hover:bg-red-100'
                : 'text-base border-blue-primary text-blue-primary hover:bg-blue-100'
            )}
            clickHandler={() => {
              joinConvo({ teamId, roomId }, { userId: selectedUserId });
              setSelectedModal(ModalTypes.kNone);
            }}
          >
            <div className="flex flex-row items-center gap-0">
              <span>Talk now!</span>
            </div>
          </SimpleButton>
          {isUserBusy && (
            <span className="max-w-[15rem] text-xs text-red-primary">
              Be aware, you may be interrupting!
            </span>
          )}
        </div>
      )}
    </div>
  );
};
