import {
  MicOff,
  NotificationsPaused,
  SnoozeOutlined,
  SnoozeRounded,
} from '@mui/icons-material';
import { NotificationPanelState, NudgeState, SelfUser } from 'api/models';
import { useRecoilValue } from 'recoil';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import { RoomContollerButton } from 'renderer/pages/dashboard/components/MainView/RoomView.tsx/RoomController/RoomControllerButton';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { IconButton } from '../Buttons/helpers/IconButton';
import { friendlyTimeDifferenceString } from '../utilities';
import { ChildNotifContainer } from './ChildNotifContainer';
import { ChildNotifTitle } from './ChildNotifTitle';
import { UserAvatar } from './user';

export const Nudges: React.FC<{
  self: SelfUser;
  activeNudges: NudgeState[];
  meta: NotificationPanelState['room'][0]['meta'];
}> = ({ activeNudges, meta }) => {
  const now = useRecoilValue(
    intervaledDateTimeAtom(DateTimeIntervals.SECOND_60)
  );
  if (activeNudges.length === 0) return null;
  console.log('render nudges');

  return (
    <ChildNotifContainer>
      <ChildNotifTitle
        icon={<PriorityHighIcon className="!w-4 !h-4 !text-gray-blue-1" />}
        title={`Pending nudge${activeNudges.length > 1 ? 's' : ''}`}
      />
      <div className="flex flex-col w-full gap-y-1 px-[2px]">
        {activeNudges.map((p) => (
          <div
            className="relative flex items-center justify-start w-full gap-1 group"
            key={p.sender.userId}
          >
            <UserAvatar user={p.sender} />
            <div className="flex flex-col w-full">
              <span className="text-xs text-left line-clamp-2">
                {p.content ??
                  `${p.sender.name?.split(/[^a-z0-9]/i, 1)[0]} wants to chat.`}
              </span>
              <span className="text-left line-clamp-1 text-2xs opacity-60">
                {friendlyTimeDifferenceString(now, new Date(p.sentTime))}
              </span>
            </div>
            <div className="flex-row gap-3">
              {p.sender.status.online && !p.sender.status.busy && (
                <RoomContollerButton
                  active={{ currentRoom: false, device: false }}
                  clickHandler={async () =>
                    window.electron?.ipcRenderer.setButtonState({
                      button: 'START_CONVO',
                      roomId: meta.roomId,
                      teamId: meta.teamId,
                      userId: p.sender.userId,
                    })
                  }
                  text={{ on: 'Unmute', off: 'Unmute' }}
                  icon={{ on: MicOff, off: MicOff }}
                  darkMode
                  tooltip
                />
              )}
              {/* <RoomContollerButton
              active={{ currentRoom: false, device: false }}
              clickHandler={async () =>
                window.electron?.ipcRenderer.setButtonState({
                  button: 'EXPAND',
                  roomId: state.roomId,
                  teamId: state.teamId,
                })
              }
              text={{ on: 'Snooze for 1 hour', off: 'Snooze for 1 hour' }}
              icon={{ on: NotificationsPaused, off: NotificationsPaused }}
              darkMode
              tooltip
            /> */}
            </div>
          </div>
        ))}
      </div>
    </ChildNotifContainer>
  );
};
