// All settings and configurations pertaining to ambient sound.

import { differenceInHours } from 'date-fns';
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
} from 'recoil';
import {
  intervaledDateTimeAtom,
  DateTimeIntervals,
} from 'renderer/common/hooks/useDateTimeMonitor';
import { ConvoKey } from 'renderer/models/Keys';
import { persistAtom } from './effects';

export enum AmbientSoundMode {
  ALL_CONVERSATIONS,
  LONG_CONVERSATIONS,
  NEVER,
}

export const ambientSoundModeName = (mode: AmbientSoundMode) => {
  switch (mode) {
    case AmbientSoundMode.ALL_CONVERSATIONS:
      return 'Always on';
    case AmbientSoundMode.LONG_CONVERSATIONS:
      return 'Extended conversations only';
    case AmbientSoundMode.NEVER:
      return 'Disabled';
    default:
      return 'Unknown';
  }
};

export const ambientSoundModeDescription = (mode: AmbientSoundMode) => {
  switch (mode) {
    case AmbientSoundMode.ALL_CONVERSATIONS:
      return "You'll hear all conversations happening in rooms you've joined. It'll slowly ramp up to the selected volume so it won't ever be startling.";
    case AmbientSoundMode.LONG_CONVERSATIONS:
      return "You'll only hear conversations that have been happening for a while. Any short conversations will never interrupt you. This is great for focusing, but still being in the loop.";
    case AmbientSoundMode.NEVER:
      return 'Never hear any ambient sound for unplanned conversations. Events and any conversations you recently left will still be audible for some time.';
    default:
      return 'Unknown';
  }
};

const AmbientSoundDefaultMode = AmbientSoundMode.LONG_CONVERSATIONS;
export const AmbientSoundVolumeConfig = {
  Default: 7,
  Min: 1,
  Max: 20,
  Scale: 5,
};

export const ambientSoundContextAtom = atom<{
  tunedInVolume: number;
  mode: AmbientSoundMode;
}>({
  key: 'ambientSoundContextAtom',
  default: {
    tunedInVolume: AmbientSoundVolumeConfig.Default,
    mode: AmbientSoundDefaultMode,
  },
  effects_UNSTABLE: [persistAtom],
});

export const ambientSoundVolumeAtom = selector({
  key: 'ambientSoundVolumeAtom',
  get: ({ get }) => get(ambientSoundContextAtom).tunedInVolume,
  set: ({ set }, val) =>
    set(ambientSoundContextAtom, (prev) => ({
      tunedInVolume: Math.min(
        Math.max(
          val instanceof DefaultValue ? AmbientSoundVolumeConfig.Default : val,
          AmbientSoundVolumeConfig.Min
        ),
        AmbientSoundVolumeConfig.Max
      ),
      mode: prev.mode,
    })),
});

export const ambientSoundModeAtom = selector({
  key: 'ambientSoundModeAtom',
  get: ({ get }) => get(ambientSoundContextAtom).mode,
  set: ({ set }, val) =>
    set(ambientSoundContextAtom, (prev) => ({
      tunedInVolume: prev.tunedInVolume,
      mode: val instanceof DefaultValue ? AmbientSoundDefaultMode : val,
    })),
});

// After some time, ignore the tune out setting for a conversation.
// Make this quite long, so it doesn't interfere.
const ConvoAmbientSoundModeRefreshHours = 3;
export enum ConvoAmbientSoundMode {
  Default,
  TuneIn,
  TuneOut,
}

const convoAmbientSoundModeImplAtom = atomFamily<
  { mode: ConvoAmbientSoundMode; date: Date },
  ConvoKey
>({
  key: 'convoAmbientSoundModeImplAtom',
  default: { mode: ConvoAmbientSoundMode.Default, date: new Date(0) },
});

export const convoAmbientSoundModeAtom = selectorFamily<
  ConvoAmbientSoundMode,
  ConvoKey
>({
  key: 'convoAmbientSoundModeAtom',
  get:
    (key) =>
    ({ get }) => {
      const { mode, date } = get(convoAmbientSoundModeImplAtom(key));
      const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_60));
      if (differenceInHours(now, date) >= ConvoAmbientSoundModeRefreshHours)
        return ConvoAmbientSoundMode.Default;
      return mode;
    },
  set:
    (key) =>
    ({ set, reset }, value) => {
      if (value instanceof DefaultValue)
        return reset(convoAmbientSoundModeImplAtom(key));
      set(convoAmbientSoundModeImplAtom(key), {
        mode: value,
        date: new Date(),
      });
    },
});
