import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { roomAtom, roomUserAtom } from 'renderer/atoms/room';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { nudgesAtom } from 'renderer/atoms/nudge';
import { availableStatusAtom } from 'renderer/connection/state';
import { RoomKey } from 'renderer/models/Keys';

export const unreadMesssagesForChannelAtom = atomFamily<
  number,
  { channelId: string }
>({
  key: 'totalUnreadChannels',
  default: 0,
});

export const totalUnreadMessagesAtom = atom<number>({
  key: 'totalUnreadChannels',
  default: 0,
});

export const totalNotifsAtom = selector<number>({
  key: 'totalNotifsAtom',
  get: ({ get }) => {
    // TODO: the unreadMsgs seems buggy -- Vaibhav said it keeps counting up
    // maybe it's a hot-reload issue or an actual bug with our unreadMsgCount.
    // for now we only use # of nudges.
    return get(nudgesAtom).incomingPings.length; // + get(totalUnreadMessagesAtom);
  },
});

export const useChatNotifications = () => {};

export const chatContextAtom = selectorFamily<
  {
    showChat: boolean;
    chats: { name: string; chatId: string; isThread: boolean }[];
  },
  RoomKey
>({
  key: 'chatContextAtom',
  get:
    (param) =>
    ({ get }) => {
      const userId = get(selfUserIdAtom);
      const { type: roomType } = get(roomAtom(param.roomId));
      const { convo } = get(roomUserAtom({ roomId: param.roomId, userId }));
      const chats = [
        {
          chatId: param.roomId,
          isThread: false,
          name: roomType === 'DM' ? 'Private Chat' : 'Channel Chat',
        },
      ];

      // Never support threaded chats for dms.
      if (roomType !== 'DM' && convo.active) {
        chats.push({
          chatId: convo.convoId,
          isThread: true,
          name: convo.isEvent ? 'Event Chat' : 'Conversation Chat',
        });
      }

      return { showChat: chats.length > 0, chats };
    },
});
