/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import LeakAddIcon from '@mui/icons-material/LeakAdd';
import LeakRemoveIcon from '@mui/icons-material/LeakRemove';
import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { ambientSoundAtom } from 'renderer/atoms/settings';
import { availableStatusAtom } from 'renderer/connection/state';
import {
  convoHoverAtom,
  maxUserVolAtom,
} from 'renderer/connection/voiceCallState';
import { RoomConvoKey } from 'renderer/models/Keys';
import { Tooltip } from 'renderer/shared/Tooltip';
import { GlooUser } from 'renderer/shared/user/GlooUser';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {
  nextEventInstanceAtom,
  roomGroupsAtom,
} from 'renderer/atoms/userUtils';
import { joinConvoLoadingAtom } from 'renderer/hooks/useConversationExpirationMonitor';
import { ClipLoader, SyncLoader } from 'react-spinners';
import {
  roomAtom,
  roomConvoAtom,
  roomConvoHasVideoAtom,
  roomEventAtom,
} from 'renderer/atoms/room';
import { MdToday } from 'react-icons/md';
import {
  bubbleExpandedAtom,
  bubbleExpandedRawAtom,
} from 'renderer/atoms/bubbleGrid';
import { videoCamActiveAtom } from 'renderer/call/components/atoms/videoCameraAtoms';

const getDims = (count: number, maxWidth: number) => {
  const width = Math.max(2, Math.min(Math.ceil(Math.sqrt(count)), maxWidth));
  const height = Math.max(1, Math.ceil(count / width));
  return { height, width };
};

export const SquareBubble = ({
  participantIds,
  roomId,
  convoId,
  convoType,
  onClick,
}: {
  participantIds: string[];
  roomId: string;
  convoId: string;
  convoType: 'EVENT' | 'CONVO';
  onClick: () => void;
}) => {
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const isSelfUserInBubble = participantIds.includes(selfUserId);
  const online = useRecoilValue(availableStatusAtom);
  const [convoHoverInfo, setConvoHover] = useRecoilState(convoHoverAtom);
  const dims = getDims(participantIds.length, 5);
  const loading = useRecoilValue(joinConvoLoadingAtom({ convoId }));
  const isCamActive = useRecoilValue(
    roomConvoHasVideoAtom({ roomId, convoId })
  );
  const setBubbleExpanded = useSetRecoilState(
    bubbleExpandedRawAtom({ convoId })
  );
  const isBubbleExpanded = useRecoilValue(
    bubbleExpandedAtom({ convoId, roomId })
  );

  // mouse leave is not good enough so when this component dies
  // we reset table hover state.
  useEffect(() => {
    return () => {
      if (convoId === convoHoverInfo?.convoId) {
        setConvoHover(null);
      }
    };
  }, []);

  return (
    <motion.div
      initial={false}
      // initial={{ opacity: 0 }}
      // animate={{ opacity: 1 }}
      // exit={{ scaleY: 0, scaleX: 0 }}
      // layout={false}
      layoutId={roomId + convoId}
      layout
      className={clsx(
        'relative w-fit h-fit rounded-[8px] ring-[1px] shadow-lg',
        [
          convoType === 'EVENT'
            ? 'ring-yellow-lightest-1 mt-6'
            : isSelfUserInBubble
            ? ' ring-blue-lighter/30'
            : ' ring-purple-lightest-1',
        ]
      )}
      style={{
        gridColumn: `span ${dims.width} / span ${dims.width}`,
      }}
      onMouseEnter={
        online ? () => setConvoHover({ roomId, convoId }) : undefined
      }
      onMouseLeave={online ? () => setConvoHover(null) : undefined}
    >
      {/* the colored filled-in part of the bubble */}
      <div className="relative p-[8px]">
        <AnimatedBg
          roomId={roomId}
          convoId={convoId}
          convoType={convoType}
          isSelfUserInBubble={isSelfUserInBubble}
          isOnline={online}
        />
        <Tooltip
          message={loading ? 'Joining' : online ? 'Join' : 'Go sync and join'}
          placement="bottom"
          disabled={isSelfUserInBubble}
        >
          <div
            className={clsx(
              'relative flex gap-3 gap-y-6 p-4 duration-300 transform rounded-[8px] h-fit w-fit min-w-[120px] min-h-[60px]',
              [
                // eslint-disable-next-line no-nested-ternary
                convoType === 'EVENT'
                  ? 'bg-yellow-lightest-1'
                  : isSelfUserInBubble
                  ? 'bg-blue-lightest-2'
                  : 'bg-purple-lightest-1',
              ],
              [
                !isSelfUserInBubble
                  ? 'hover:scale-105 hover:cursor-pointer'
                  : '',
              ]
            )}
            style={{
              display: 'grid',
              gridTemplateColumns: `repeat(${dims.width}, minmax(0, 1fr))`,
              gridTemplateRows: `repeat(${dims.height}, minmax(0, 1fr))`,
            }}
            onClick={loading || isSelfUserInBubble ? undefined : onClick}
          >
            {participantIds.map((p, i) => {
              return (
                <motion.div
                  key={roomId + p}
                  layoutId={roomId + p}
                  initial={false}
                  // layout={false}
                  transition={{
                    type: 'tween',
                  }}
                  layout="position"
                  className="bg-inherit"
                >
                  <GlooUser roomId={roomId} userId={p} key={p} />
                </motion.div>
              );
            })}
          </div>
        </Tooltip>
      </div>
      {convoType === 'EVENT' && (
        <EventLabel eventId={convoId} roomId={roomId} />
      )}
      <BubbleLabel
        hasUsers={participantIds.length > 0}
        isSelfUserInBubble={isSelfUserInBubble}
        convoType={convoType}
        convoId={convoId}
        roomId={roomId}
      />
      {isSelfUserInBubble && isCamActive && (
        <button
          type="button"
          className={clsx(
            'absolute flex text-xs right-1 top-1 text-blue-primary/70 hover:text-blue-primary hover:scale-110',
            [
              convoType === 'EVENT'
                ? 'text-yellow-primary/70 hover:text-yellow-primary'
                : 'text-blue-primary/70 hover:text-blue-primary',
            ]
          )}
          onClick={() => {
            setBubbleExpanded((prev) => !prev);
          }}
        >
          {/* <span className="px-1 rounded-lg bg-white-1"></span> */}
          {isBubbleExpanded ? (
            <svg
              className="w-7 h-7"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M10.992 20H9v-5H4v-2h7l-.008 7ZM20 11h-7l.007-7H15v5h5v2Z"
              />
            </svg>
          ) : (
            <svg
              className="w-7 h-7"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M5 19v-6h2v4h4v2Zm12-8V7h-4V5h6v6Z"
              />
            </svg>
          )}
        </button>
      )}

      {/* TODO: make button black, on hover blacker */}
    </motion.div>
  );
};
const EventLabel = ({
  eventId,
  roomId,
}: {
  eventId: string;
  roomId: string;
}) => {
  const event = useRecoilValue(roomEventAtom({ roomId, eventId }));
  const { timeUntil, instance } = useRecoilValue(
    nextEventInstanceAtom({ roomId, eventId })
  );
  if (!event) return null;
  return (
    <div className="absolute inset-x-0 flex flex-col text-sm -top-10">
      <div className="flex flex-row items-center justify-center text-black-primary">
        <MdToday />{' '}
        <span className="truncate whitespace-nowrap">
          {event.name ?? 'Untitled Event'}
        </span>
      </div>
      {instance &&
        (timeUntil > 0 ? (
          <span className="text-xs text-black-lightest/80">
            Starting in {timeUntil} minutes
          </span>
        ) : (
          <span className="text-xs font-light text-black-lightest/80">
            {(instance.start as Date).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}{' '}
            -{' '}
            {(instance.end as Date).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </span>
        ))}
    </div>
  );
};

const BubbleLabel = ({
  hasUsers,
  isSelfUserInBubble,
  convoId,
  roomId,
  convoType,
}: {
  hasUsers: boolean;
  isSelfUserInBubble: boolean;
  convoId: string;
  roomId: string;
  convoType: 'EVENT' | 'CONVO';
}) => {
  const loading = useRecoilValue(joinConvoLoadingAtom({ convoId }));
  const convoHoverInfo = useRecoilValue(convoHoverAtom);
  const ambientSound = useRecoilValue(ambientSoundAtom);

  // const tableGroups = useRecoilValue(roomGroupsAtom({ roomId }));
  // if lots of convos make label occupy less space
  // const enableAmbientSoundText = tableGroups.CONVOS.length === 1;
  const isEvent = convoType === 'EVENT';
  const textColor = isEvent ? 'text-yellow-primary' : 'text-purple-primary';

  if (isSelfUserInBubble) return null;
  const content = loading ? (
    <span className="flex flex-row items-center justify-center gap-2 px-1 m-auto rounded-lg bg-white-1 w-fit">
      Joining <ClipLoader size={16} color={textColor} />
    </span>
  ) : isEvent && !hasUsers ? (
    <span className="flex flex-row items-center justify-center gap-2 px-1 m-auto rounded-lg bg-white-1 w-fit">
      Click to join
    </span>
  ) : convoId === convoHoverInfo?.convoId ? (
    <span className="px-1 rounded-lg bg-white-1">Listening in...</span>
  ) : ambientSound.muted ? (
    <span className="px-1 rounded-lg bg-white-1">
      <LeakRemoveIcon className="!w-[16px] !h-[16px] animate-[pulse_4s_ease-in-out_infinite]" />
    </span>
  ) : (
    <span className="px-1 bg-white-1">
      <LeakAddIcon className="!w-[16px] !h-[16px] animate-[pulse_4s_ease-in-out_infinite]" />
      {' Ambiently listening'}
    </span>
  );

  return (
    <div className={clsx('absolute inset-x-0 text-xs -top-2 ', textColor)}>
      {content}
    </div>
  );
};

const AnimatedBg = ({
  roomId,
  convoId,
  isSelfUserInBubble,
  convoType,
  isOnline,
}: RoomConvoKey & {
  isSelfUserInBubble: boolean;
  isOnline: boolean;
  convoType: 'EVENT' | 'CONVO';
}) => {
  const vol = useRecoilValue(maxUserVolAtom({ roomId, convoId }));

  return (
    <motion.div
      className={clsx(
        'absolute inset-0 rounded-[8px]',
        [
          // eslint-disable-next-line no-nested-ternary
          convoType === 'EVENT'
            ? 'bg-yellow-lighter'
            : isSelfUserInBubble
            ? 'bg-blue-lighter'
            : 'bg-purple-lightest-1',
        ],
        [
          isOnline && !isSelfUserInBubble
            ? 'hover:scale-105 hover:cursor-pointer'
            : '',
        ]
      )}
      layoutId={`${roomId + convoId}bubble`}
      layout
      animate={{
        scale: 0.7 + vol * 0.3,
        opacity: 0.0 + (1 - vol) * 0.5,
      }}
      // style={{
      //   bottom: vol,
      //   top: vol,
      //   right: vol,
      //   left: vol,
      // }}
    />
  );
};
