import React, { useEffect, useMemo } from 'react';
import {
  RecoilValue,
  selectorFamily,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';
import { roomConvoAtom, roomUserAtom } from 'renderer/atoms/room';
import {
  bannerSettingsOnAtom,
  NotificationSettingsToggle,
  soundSettingsOnAtom,
} from 'renderer/atoms/settings';
import { FadeInAudio } from 'renderer/audio/FadeInAudio';
import {
  isStreamActiveFirebase,
  StreamType,
} from 'renderer/call/components/atoms/CallStateAtoms';
import {
  localMicActiveAtom,
  micRoomNameAtom,
} from 'renderer/call/components/MicrophoneProvider';
import { isWindowFocusedAtom } from 'renderer/common/hooks/useUserInputActiveMonitor';
import { buttonActive, ButtonType } from 'renderer/connection/buttons';
import { availableStatusAtom } from 'renderer/connection/state';
import { convoHoverAtom } from 'renderer/connection/voiceCallState';
import deafen_off from 'resources/audio/deafen_off.ogg';
import deafen_on from 'resources/audio/deafen_on.ogg';
import mic_off from 'resources/audio/mic_off.ogg';
import mic_on from 'resources/audio/mic_on.ogg';
import tableJoined from 'resources/audio/notification.ogg';
import overhear_off from 'resources/audio/overhear_off.ogg';
import overhear_on from 'resources/audio/overhear_on.ogg';
import { usePrevious } from './usePrevious';

type RoomKey = {
  roomId: string;
};
const tableJoinedSound = new FadeInAudio(new Audio(tableJoined), 0.9);

const micOnSound = new FadeInAudio(new Audio(mic_on), 0.5);
const micOffSound = new FadeInAudio(new Audio(mic_off), 0.7);

const overhearOnSound = new FadeInAudio(new Audio(overhear_on));
const overhearOffSound = new FadeInAudio(new Audio(overhear_off));

// const deafenOnSound = new FadeInAudio(new Audio(deafen_on), 0.3);
// const deafenOffSound = new FadeInAudio(new Audio(deafen_off), 0.3);

type SoundProps<T> = {
  atom: RecoilValue<T>;
  soundSetting: NotificationSettingsToggle;
  onSound: FadeInAudio;
  offSound?: FadeInAudio;
  onMessage?: { title: string; data: NotificationOptions };
  offMessage?: { title: string; data: NotificationOptions };
  onFirst?: boolean;
  conditional?: (curr: T, prev?: T) => boolean;
  soundOnFocusOnly?: boolean;
};

const NotificationToggle = <T,>({
  atom,
  soundSetting,
  onSound,
  offSound,
  onMessage,
  offMessage,
  onFirst = false,
  conditional = (curr) => !!curr,
  soundOnFocusOnly = true,
}: SoundProps<T>) => {
  const windowFocused = useRecoilValue(isWindowFocusedAtom);
  const soundOn = useRecoilValue(soundSettingsOnAtom(soundSetting));
  const useSound = useMemo(
    () =>
      (soundOnFocusOnly && windowFocused) ||
      (!soundOnFocusOnly && !windowFocused && soundOn),
    [windowFocused, soundOn, soundOnFocusOnly]
  );
  const useBanner = useRecoilValue(bannerSettingsOnAtom(soundSetting));

  const currState = useRecoilValue(atom);
  const prevStatus = usePrevious(currState);

  useEffect(() => {
    if (!useSound) return;
    if (!onFirst && prevStatus === undefined) return;
    if (prevStatus === currState) return;

    if (conditional(currState, prevStatus)) {
      onSound.play();
    } else {
      offSound?.play();
    }

    // We don't particularly care about a sound playing when useSound changes.
  }, [currState, prevStatus, useSound, conditional]);

  useEffect(() => {
    if (!useBanner) return;
    if (!onFirst && prevStatus === undefined) return;
    if (prevStatus === currState) return;
    const createNotification = async (item: {
      title: string;
      data?: NotificationOptions;
    }) => {
      return new Notification(item.title, {
        ...item.data,
        silent: true,
      });
      // TODO: until we get navigator.serviceWorker.ready working.
      // try {
      //   logger.info('Notif: Showing:', item);
      //   const perms = await Notification.requestPermission();
      //   logger.info('Notif: Perms:', perms, item);
      //   if (perms === 'granted') {
      //     const registration = await navigator.serviceWorker.ready;
      //     logger.info('Notif: registration:', registration, item);
      //     registration.showNotification(item.title, item.data);
      //   }
      // } catch (err) {
      //   logger.info('Notif: Err', err);
      // }
    };

    if (conditional(currState, prevStatus)) {
      if (onMessage) {
        createNotification(onMessage);
      }
    } else if (offMessage) {
      createNotification(offMessage);
    }

    // We don't particularly care about a sound playing when useSound changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currState, prevStatus]);
  return <></>;
};

const otherUsersAtTableAtom = selectorFamily<
  { convoId: string; count: number; isSelfMicActive: boolean },
  RoomKey
>({
  key: 'otherUsersAtTableAtom',
  get:
    ({ roomId }) =>
    ({ get }) => {
      const selfUserId = get(selfUserIdAtom);
      const selfUser = get(roomUserAtom({ roomId, userId: selfUserId }));
      if (!selfUser.convo.convoId) {
        return { convoId: 'none', count: 0, isSelfMicActive: false };
      }
      const tableUsers = get(
        roomConvoAtom({ roomId, convoId: selfUser.convo.convoId })
      )?.users;
      const isSelfMicActive = get(localMicActiveAtom({ roomId }));
      const isAvailable = get(availableStatusAtom);
      if (!isAvailable) {
        return { convoId: 'none', count: 0, isSelfMicActive: false };
      }

      if (!tableUsers)
        return {
          convoId: 'none',
          count: isSelfMicActive ? 1 : 0,
          isSelfMicActive,
        };
      const numMics = tableUsers
        .map((u) => {
          const isMicActive = get(
            isStreamActiveFirebase({
              roomId,
              userId: u.userId,
              stream: StreamType.MIC,
            })
          );
          return isMicActive;
        })
        .filter((online) => online).length;

      return {
        convoId: selfUser.convo.convoId,
        count: numMics,
        isSelfMicActive,
      };
    },
});

export const RoomSoundEffectsProvider: React.FC<RoomKey> = ({ roomId }) => {
  const isWindowFocused = useRecoilValue(isWindowFocusedAtom);
  // hack because sometimes onmouseleave doesn't work and we don't want to
  // be stuck "overhearing" another convo at full volume without mousing
  // over it. https://github.com/facebook/react/issues/6807
  const setTableHover = useSetRecoilState(convoHoverAtom);
  useEffect(() => {
    if (!isWindowFocused) {
      setTableHover(null);
    }
  }, [isWindowFocused]);

  return (
    <>
      <NotificationToggle
        atom={otherUsersAtTableAtom({ roomId })}
        soundSetting={NotificationSettingsToggle.OTHER_JOIN_TABLE}
        onSound={tableJoinedSound}
        conditional={(curr, prev) => {
          const otherUserActiveMics =
            curr.count - (curr.isSelfMicActive ? 1 : 0);
          return (
            curr.convoId === prev?.convoId &&
            prev.count === 0 &&
            otherUserActiveMics > 0
          );
        }}
        soundOnFocusOnly={false}
      />
      <NotificationToggle
        atom={buttonActive({ button: ButtonType.OVERHEARING })}
        soundSetting={NotificationSettingsToggle.OVERHEARING_TOGGLE}
        onSound={overhearOnSound}
        offSound={overhearOffSound}
      />
    </>
  );
};

export const SoundEffectsProvider: React.FC = () => {
  return (
    <>
      <NotificationToggle
        atom={buttonActive({ button: ButtonType.MIC })}
        soundSetting={NotificationSettingsToggle.STATUS_MIC}
        onSound={micOnSound}
        offSound={micOffSound}
        // TODO: uncommeted for now since this incorrectly shows
        // when the mic is muted from the notification secondary window.
        // offMessage={{
        //   title: 'Mic muted',
        //   data: { body: 'Due to inactivity in the table.' },
        // }}
      />
      <NotificationToggle
        atom={micRoomNameAtom}
        soundSetting={NotificationSettingsToggle.STATUS_MIC}
        onSound={micOnSound}
        conditional={(curr, prev) => !!(curr && prev && curr !== prev)}
      />
      {/* <NotificationToggle
        atom={availableStatusAtom}
        soundSetting={NotificationSettingsToggle.STATUS_CHANGE}
        onSound={deafenOffSound}
        offSound={deafenOnSound}
        // onMessage={{ title: 'Online', data: {} }}
        // offMessage={{ title: 'Offline', data: {} }}
        onFirst
      /> */}
    </>
  );
};
