import {
  add,
  differenceInDays,
  differenceInMilliseconds,
  differenceInMinutes,
  format,
  isAfter,
  isWeekend,
  nextMonday,
} from 'date-fns';
import { selector } from 'recoil';
import { isMeetingDetectionEnabledAtom } from 'renderer/atoms/settings';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import {
  isMicUsedExternallyAtom,
  isUserInputActiveAtom as userInputActivityStateAtom,
  UserActivityState,
} from 'renderer/common/hooks/useUserInputActiveMonitor';
import { logger } from 'renderer/common/LogCreator';
import { buttonOverrideTimeMinutes } from 'renderer/constants';
import { UserStatus } from 'renderer/models/Api';
import { buttonActive, buttonPressedTimeAtom, ButtonType } from './buttons';
import { selfUserConvoActiveInAnyRoom } from './voiceCallState';

enum ButtonMode {
  MINS,
  TILL_TMRW,
}

const ButtonOverrideMode = ButtonMode.TILL_TMRW;

const NextMorningActiveTime = (date: Date) => {
  const targetHour = 9;

  let copy = new Date(date);
  copy.setHours(targetHour);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);

  if (date.getHours() >= targetHour) {
    copy = add(copy, { days: 1 });
  }
  if (isWeekend(copy)) {
    copy = nextMonday(copy);
  }

  return copy;
};

const buttonActiveUntil = (mode: ButtonMode, date: Date) => {
  return mode === ButtonMode.MINS
    ? add(date, {
        minutes: buttonOverrideTimeMinutes,
      })
    : NextMorningActiveTime(date);
};

export const externalMicStatusAtom = selector<boolean>({
  key: 'externalMicStatus',
  get: ({ get }) => {
    const statusButton = get(buttonActive({ button: ButtonType.STATUS }));
    const micButton = get(buttonActive({ button: ButtonType.MIC }));
    const { inUse: micExternallyActive, time: lastExternalMicOnTime } = get(
      isMicUsedExternallyAtom
    );

    const lastPressedMicButton = get(
      buttonPressedTimeAtom({ button: ButtonType.MIC })
    );
    const lastPressedButton = get(
      buttonPressedTimeAtom({ button: ButtonType.STATUS })
    );

    const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND));

    const isMicPressedRecently =
      isAfter(lastPressedMicButton, lastExternalMicOnTime) &&
      differenceInMilliseconds(now, lastPressedMicButton) < 1500;
    const isStatusButtonPressedRecently =
      statusButton &&
      isAfter(lastPressedButton, lastExternalMicOnTime) &&
      differenceInMinutes(now, lastPressedButton) <= 10;

    // Ignore the external mic message in some cases.
    const ignoreExternalMic =
      // Mic is active. Longer.
      micButton ||
      isMicPressedRecently ||
      // User pressed the status button within the last 10 minutes.
      isStatusButtonPressedRecently;
    if (ignoreExternalMic) return false;
    return micExternallyActive;
  },
});

// Tells you whether user is online listening or not.
// For now the "busy" status is separated into its own calculation since
// a user can be LISTENING but also "busy" (even if our backend model only allows one enum to be set)
export const availableStatusAtom = selector<boolean>({
  key: 'availableStatus',
  get: ({ get }) => {
    const buttonKey = { button: ButtonType.STATUS };
    const isStatusButtonActive = get(buttonActive(buttonKey));
    const micButton = get(buttonActive({ button: ButtonType.MIC }));
    const pcActive = get(userInputActivityStateAtom);
    const micExternallyActive = get(externalMicStatusAtom);
    const userInConveration = get(selfUserConvoActiveInAnyRoom(30));

    const lastPressedButtonTime = get(buttonPressedTimeAtom(buttonKey));

    // Get the difference in time from the last time a button was pressed.
    // The button press, when disabled, only is until 9am the next day.
    const buttonExpireTime = buttonActiveUntil(
      ButtonOverrideMode,
      lastPressedButtonTime
    );
    const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_60));

    const isMeetingDetectionEnabled = get(isMeetingDetectionEnabledAtom);

    if (micExternallyActive && isMeetingDetectionEnabled) {
      // If you're mic is used else where, then mark as unavailable
      return false;
    }

    logger.info('onlineStatus', {
      pcActive,
      isStatusButtonActive,
      micButton,
      userInConveration,
      lastPressedButtonTime,
      buttonExpireTime,
    });
    switch (pcActive) {
      case UserActivityState.LOCKED:
        // If you're locked, you go offline.
        return false;
      case UserActivityState.IDLE_SHORT:
        return isStatusButtonActive;
      case UserActivityState.IDLE_LONG:
        return isStatusButtonActive && (micButton || userInConveration);
      case UserActivityState.ONLINE:
        // Otherwise use the status button.
        return isStatusButtonActive ? true : isAfter(now, buttonExpireTime);
      default:
        throw new Error('Unknow PC State');
    }
  },
});

export const unavailableStatusReason = selector<string>({
  key: 'unavailableStatusReason',
  get: ({ get }) => {
    const buttonKey = { button: ButtonType.STATUS };
    const statusButton = get(buttonActive(buttonKey));
    const micButton = get(buttonActive({ button: ButtonType.MIC }));
    const pcActive = get(userInputActivityStateAtom);
    const micExternallyActive = get(externalMicStatusAtom);

    // Get the difference in time from the last time a button was pressed.
    // The button press, when disabled, only is until 9am the next day.
    const lastPressedButton = get(buttonPressedTimeAtom(buttonKey));
    const buttonExpireTime = buttonActiveUntil(
      ButtonOverrideMode,
      lastPressedButton
    );
    const now = get(intervaledDateTimeAtom(DateTimeIntervals.SECOND_60));

    const listeningToAll = "You're in sync and reachable";

    const isMeetingDetectionEnabled = get(isMeetingDetectionEnabledAtom);
    if (micExternallyActive && isMeetingDetectionEnabled) {
      // If you're mic is used else where, then disable the mic.
      return "In a meeting? Just in case, you're now async!";
    }

    switch (pcActive) {
      case UserActivityState.LOCKED:
        // If you're locked, you go offline.
        return 'Gloo turns async when your device locks.';
      case UserActivityState.IDLE_SHORT:
        if (statusButton) {
          return listeningToAll;
        }
        return "Gloo turns async when you're idle";
      case UserActivityState.IDLE_LONG:
        // If you're idle, use the mic to know if you're offline.
        return statusButton && micButton
          ? listeningToAll // 'Online due to mic'
          : "Gloo turns async when you're idle.";
      case UserActivityState.ONLINE: {
        // Otherwise use the status button.
        if (statusButton) {
          return listeningToAll; // 'Online due to button press';
        }
        if (isAfter(now, buttonExpireTime)) {
          return listeningToAll; // 'Online due to time expired';
        }
        const daysUntil = differenceInDays(buttonExpireTime, now);
        let message = '';
        if (daysUntil === 1) {
          message = ' tomorrow';
        } else if (daysUntil > 1) {
          message = ' Monday';
        }
        return `You're async until after${message} ${format(
          buttonExpireTime,
          'hh:mm aa'
        )}`;
      }
      default:
        throw new Error('Unknow PC State');
    }
  },
});
