import { Dialog } from '@headlessui/react';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { MdClose } from 'react-icons/md';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { AddTeamMenu } from 'renderer/pages/dashboard/components/MainView/NoTeamsOrRoomsView/AddTeamMenu';
import DiamondPattern from 'resources/diamondpattern.png';
import { AmbientSoundModal } from './AmbientSoundModal';
import {
  modalTitleAtom,
  ModalTypes,
  selectedModalAtom,
  useModalErrors,
} from './atoms';
import { CreateRoomModal } from './CreateRoomModal';
import { CreateOrUpdateEventModal } from './events/CreateOrUpdateEventModal';
import { LinkCalendarModal } from './events/LinkCalendarModal';
import { ListEventsForRoomModal } from './events/ListEventsForRoomModal';
import { InviteModal } from './InviteModal';
import { LeaveRoomModal } from './LeaveRoomModal';
import { RenameRoomModal } from './RenameRoomModal';
import { ScreenShareMenuModal } from './screenshare/ScreenShareMenuModal';
import {
  selectedViewTrackAtom,
  ViewScreenShareModal,
} from './screenshare/ViewScreenShareModal';
import { TalkToBusyUserModal } from './TalkToBusyUserModal';
import { CreateTeamModal } from './team/CreateTeamModal';
import { TeamInviteModal } from './TeamInviteModal';

type ModalProperties = {
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  useDarkMode?: boolean;
};

const CloseButton = ({
  onCloseHandler,
  useDarkMode,
}: {
  onCloseHandler: () => void;
  useDarkMode?: boolean;
}) => {
  return (
    <button
      type="button"
      className={`absolute top-0 right-0 flex items-start p-1 ml-auto text-gray-3 -mr-4 -mt-2 ${
        useDarkMode ? '' : ''
      }`}
      onClick={onCloseHandler}
    >
      <MdClose
        className={`w-5 h-5 ${
          useDarkMode ? 'text-white-1 hover:text-gray-500' : 'hover:text-gray-5'
        }`}
      />
    </button>
  );
};

const ModalWrapper: React.FC<ModalProperties> = ({
  onClose,
  className,
  children,
  useDarkMode,
}) => {
  const setSelectedModal = useSetRecoilState(selectedModalAtom);
  const title = useRecoilValue(modalTitleAtom);

  const { errorAtom } = useModalErrors();
  const modalError = useRecoilValue(errorAtom);

  const onCloseHandler = () => {
    setSelectedModal(ModalTypes.kNone);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open
      onClose={onCloseHandler}
      // Modals are the second highest priorty.
      className="relative z-40"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black-primary/30" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex p-4 ">
        {/* The actual dialog panel  */}
        <Dialog.Panel
          className={clsx(
            `flex flex-col m-auto overflow-scroll rounded-md shadow-md`,
            className,
            [useDarkMode ? 'bg-black-primary' : 'bg-neutral-2']
          )}
        >
          <div className={clsx('w-full h-full p-4')}>
            {title ? (
              <div className="relative flex flex-row items-start justify-center gap-4">
                <Dialog.Title className="w-full px-5 pb-3 text-xl font-semibold text-center">
                  {title}
                </Dialog.Title>
                <CloseButton
                  onCloseHandler={onCloseHandler}
                  useDarkMode={useDarkMode}
                />
              </div>
            ) : (
              <div className="relative h-4">
                <CloseButton
                  onCloseHandler={onCloseHandler}
                  useDarkMode={useDarkMode}
                />
              </div>
            )}
            {modalError && (
              <Dialog.Description className="text-sm text-center text-red-500">
                Error
                <br />
                {modalError}
              </Dialog.Description>
            )}
            <div className="flex flex-col justify-center w-full">
              {children}
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

const ModalContent = {
  [ModalTypes.kScreenshareMenu]: {
    useDarkMode: false,
    data: <ScreenShareMenuModal />,
  },
  [ModalTypes.kScreenshareView]: {
    useDarkMode: true,
    data: <ViewScreenShareModal />,
  },
  [ModalTypes.kLeaveRoom]: {
    useDarkMode: false,
    data: <LeaveRoomModal />,
  },
  [ModalTypes.kTeamInviteModal]: {
    useDarkMode: false,
    data: <TeamInviteModal />,
  },
  [ModalTypes.kInviteModal]: {
    useDarkMode: false,
    data: <InviteModal />,
  },
  [ModalTypes.kAddTeamMenu]: {
    useDarkMode: false,
    data: <AddTeamMenu inModal />,
  },
  [ModalTypes.kBrowseOrCreateRoom]: {
    useDarkMode: false,
    data: null,
  },
  [ModalTypes.kRenameRoom]: {
    useDarkMode: false,
    data: <RenameRoomModal />,
  },
  [ModalTypes.kTalkToBusyUser]: {
    useDarkMode: false,
    data: <TalkToBusyUserModal />,
  },
  [ModalTypes.kCreateRoom]: {
    useDarkMode: false,
    data: <CreateRoomModal />,
  },
  [ModalTypes.kCreateTeam]: {
    useDarkMode: false,
    data: <CreateTeamModal />,
  },
  [ModalTypes.kCreateOrUpdateEvent]: {
    useDarkMode: false,
    data: <CreateOrUpdateEventModal />,
  },
  [ModalTypes.kListEventsForRoom]: {
    useDarkMode: false,
    data: <ListEventsForRoomModal />,
  },
  [ModalTypes.kLinkCalendar]: {
    useDarkMode: false,
    data: <LinkCalendarModal />,
  },
  [ModalTypes.kConfigureAmbientSound]: {
    useDarkMode: false,
    data: <AmbientSoundModal />,
  },
};

export const Modal = () => {
  const selectedModal = useRecoilValue(selectedModalAtom);
  const setSelectedTrack = useSetRecoilState(selectedViewTrackAtom);
  let onCloseHandler;
  if (selectedModal === ModalTypes.kNone) return null;

  if (selectedModal === ModalTypes.kScreenshareMenu) {
    onCloseHandler = () => {
      setSelectedTrack(null);
    };
  }

  const { useDarkMode, data } = ModalContent[selectedModal];

  return (
    <ErrorBoundary fallback={<>Error loading modal</>}>
      <ModalWrapper useDarkMode={useDarkMode} onClose={onCloseHandler}>
        {data}
      </ModalWrapper>
    </ErrorBoundary>
  );
};
