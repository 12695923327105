import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import {
  MdCalendarToday,
  MdCheck,
  MdEdit,
  MdPersonAdd,
  MdRefresh,
  MdSync,
} from 'react-icons/md';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { connectionStateAtom } from 'renderer/atoms/call';
import { selectedRoomAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import { useShouldJoinCall } from 'renderer/call/components/JoinCallMonitor';
import { trpc } from 'renderer/common/client/trpc';
import { availableStatusAtom } from 'renderer/connection/state';
import { TeamRoomKey } from 'renderer/models/Keys';
import { IconButton } from 'renderer/shared/Buttons/helpers/IconButton';
import { ModalTypes, selectedModalAtom } from 'renderer/shared/Modals/atoms';
import { Tooltip } from 'renderer/shared/Tooltip';
import { SimpleButton, SubtitleButton } from 'renderer/shared/SimpleButton';

export const RoomConnectionDot = () => {
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const { shouldJoinCall } = useShouldJoinCall({ roomId });
  const connectionState = useRecoilValue(
    connectionStateAtom({ roomId, isCameraStream: false })
  );
  const isAvailable = useRecoilValue(availableStatusAtom);
  const [showReload, setShowReload] = useState(false);
  const showState = useMemo(() => {
    switch (connectionState) {
      case 'CONNECTED':
        return isAvailable && !shouldJoinCall;
      case 'DISCONNECTED':
        return !isAvailable && shouldJoinCall;
      default:
        return true;
    }
  }, [connectionState, shouldJoinCall, isAvailable]);

  useEffect(() => {
    if (shouldJoinCall && isAvailable) {
      if (connectionState === 'DISCONNECTED') {
        const tm = setTimeout(() => setShowReload(true), 5_000);
        return () => clearTimeout(tm);
      }
    }
    setShowReload(false);
  }, [shouldJoinCall, connectionState, isAvailable]);

  if (!showState) return null;

  const getColor = () => {
    switch (connectionState) {
      case 'CONNECTED':
        return 'bg-green-primary';
      case 'CONNECTING':
        return 'bg-yellow-500 animate-pulse';
      case 'DISCONNECTED':
        return 'bg-red-500';
      case 'DISCONNECTING':
        return 'bg-red-200 animate-pulse';
      case 'RECONNECTING':
        return 'bg-gray-500 animate-pulse';
      default:
        return '';
    }
  };

  const getMessage = () => {
    switch (connectionState) {
      case 'CONNECTED':
        return 'All systems go!';
      case 'CONNECTING':
        return 'Establishing a connection to your team';
      case 'DISCONNECTED':
        return "Connection broken! :'(";
      case 'DISCONNECTING':
        return undefined;
      case 'RECONNECTING':
        return "We're doing our best to reconnect you!";
      default:
        return '';
    }
  };

  return (
    <>
      <Tooltip message={getMessage()}>
        <SimpleButton
          className={clsx(
            'flex w-fit px-2 duration-300 transform border rounded-md text-sm',
            'text-gray-3'
          )}
          clickHandler={() => {}}
          disabled
        >
          <div className="flex flex-row items-center gap-1">
            <div className={clsx('w-2 h-2 rounded-full', getColor())} />
            <span className="capitalize">{connectionState.toLowerCase()}</span>
          </div>
        </SimpleButton>
      </Tooltip>
      {showReload && (
        <SimpleButton
          className={clsx(
            'flex w-fit px-2 duration-300 transform border rounded-md text-sm',
            'border-red-500 bg-red-100 animate-pulse hover:scale-105 text-red-500'
          )}
          clickHandler={window.location.reload}
        >
          <div className="flex flex-row items-center gap-1">
            <MdRefresh />
            <span>Reload Gloo</span>
          </div>
        </SimpleButton>
      )}
    </>
  );
};

const SyncButton: React.FC<TeamRoomKey> = ({ teamId, roomId }) => {
  const [inProgress, setInProgress] = useState(false);
  const [showCheck, setShowCheck] = useState(false);

  const syncButton = trpc.useMutation('rooms.sync', {
    onMutate: () => {
      setInProgress(true);
    },
    onSettled: () => {
      setShowCheck(true);
      setInProgress(false);
      setTimeout(() => setShowCheck(false), 2_000);
    },
    onError: (err) => {
      console.log('FAILED ROOM SYNC', err);
    },
  });
  if (showCheck) {
    return <MdCheck className="text-green-primary" />;
  }
  if (inProgress) {
    return <MdSync className="animate-spin text-green-primary" />;
  }

  return (
    <IconButton
      // toolTip="Sync with slack"
      className="w-full h-full text-gray-3 hover:scale-105 hover:text-black-primary"
      clickHandler={() => {
        setInProgress(true);
        syncButton.mutate({
          teamId,
          roomId,
          integration: 'slack',
        });
      }}
    >
      <MdSync />
    </IconButton>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EventsButton: React.FC = () => {
  const setSelectedModal = useSetRecoilState(selectedModalAtom);

  return (
    <SubtitleButton
      clickHandler={() => {
        setSelectedModal(ModalTypes.kListEventsForRoom);
      }}
    >
      <MdCalendarToday />
      <span>Events</span>
    </SubtitleButton>
  );
};

export const RoomButtons: React.FC = () => {
  const room = useRecoilValue(selectedRoomAtom);
  const integratedRoom = !!room.integrations?.slack;
  const setModal = useSetRecoilState(selectedModalAtom);

  return (
    <div className="relative flex flex-row flex-wrap items-center gap-2 pt-1">
      {room.type === 'SLACK' && (
        <div className="w-3 h-3 lg:w-5 lg:h-5">
          <SyncButton teamId={room.teamId} roomId={room.roomId} />
        </div>
      )}
      {room.type === 'DEFAULT' && (
        <>
          <SubtitleButton
            clickHandler={() => {
              setModal(ModalTypes.kRenameRoom);
            }}
          >
            <MdEdit />
            <span>Rename</span>
          </SubtitleButton>

          <SubtitleButton
            clickHandler={() => {
              setModal(ModalTypes.kInviteModal);
            }}
          >
            <MdPersonAdd />
            <span>Add members</span>
          </SubtitleButton>
        </>
      )}
      <EventsButton />
    </div>
  );
};
