/* eslint-disable react/no-unescaped-entities */
import { Transition } from '@headlessui/react';
import clsx from 'clsx';

import { Fragment, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selfGlooUserAtom } from 'renderer/atoms/glooUser';
import {
  availableStatusAtom,
  unavailableStatusReason,
} from 'renderer/connection/state';
import { SimpleButton } from 'renderer/shared/SimpleButton';
import { GlooSelfUserWithStatus } from 'renderer/shared/user/GlooUser';
import { StatusToggle } from '../../SidePanel/StatusPanel/StatusToggle';

export const OfflinePanel = () => {
  const {
    status: { online },
  } = useRecoilValue(selfGlooUserAtom);
  const isUserAvailable = useRecoilValue(availableStatusAtom);
  const inStateTransition = useMemo(
    () => online !== isUserAvailable,
    [online, isUserAvailable]
  );

  return (
    <>
      {/* for some reason this div sometimes shows up in a semi-transparent way and blocks clicks if we don't hide it completely */}
      <Transition
        appear
        show={!isUserAvailable || inStateTransition}
        as={Fragment}
        enter="transition ease-out duration-400"
        enterFrom="opacity-0 translate-y-14"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-400"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-10"
      >
        <div>
          <SimpleButton
            className={clsx(
              'flex w-fit px-2 duration-300 rounded-md text-sm',
              'bg-gray-5 text-white-1'
            )}
            clickHandler={() => {}}
            disabled
          >
            <div className="flex flex-row items-center gap-1">
              {inStateTransition ? 'Going' : "You're"}{' '}
              {isUserAvailable ? 'sync' : 'async'}
            </div>
          </SimpleButton>
        </div>
      </Transition>
    </>
  );
};
