import { useState } from 'react';
import { ClipLoader, SyncLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import {
  dmRoomAtom,
  roomAvailableAtom,
  useRoomAtomSetters,
} from 'renderer/atoms/room';
import { FirebaseSyncAvailability } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { usePageSelector } from 'renderer/hooks/usePageSelector';
import { TeamKey, UserKey } from 'renderer/models/Keys';
import { GlooIcon } from 'renderer/shared/icons/GlooIcon';
import { useModalErrors } from 'renderer/shared/Modals/atoms';
import { SubtitleButton } from 'renderer/shared/SimpleButton';
import { Spinner } from 'renderer/shared/Spinner';
import { Tooltip } from 'renderer/shared/Tooltip';

export const DirectMessageButton: React.FC<TeamKey & UserKey> = ({
  teamId,
  userId,
}) => {
  const [inProgress, setInProgress] = useState(false);
  const { selectRoom } = usePageSelector();
  const { addOrUpdateRoom } = useRoomAtomSetters();
  const { setModalError } = useModalErrors();
  const dmRoomId = useRecoilValue(dmRoomAtom({ teamId, userId }));
  const roomReady = useRecoilValue(
    roomAvailableAtom({ roomId: dmRoomId ?? 'DEFAULT' })
  );
  const createDm = trpc.useMutation('rooms.create-dm', {
    onError: (err) => {
      setModalError(err.message);
    },
    onSuccess: (room) => {
      addOrUpdateRoom(room);
      selectRoom({ roomId: room.roomId, teamId });
    },
  });

  const onClick = async () => {
    if (roomReady !== FirebaseSyncAvailability.NOT_AVAILABLE && dmRoomId) {
      selectRoom({ roomId: dmRoomId, teamId });
    } else {
      await createDm.mutateAsync({ teamId, withUser: userId });
    }
  };

  return (
    <Tooltip message="Open direct messages">
      <SubtitleButton
        disabled={inProgress}
        clickHandler={() => {
          setInProgress(true);
          onClick()
            .finally(() => {
              setInProgress(false);
            })
            .catch((err) => console.error(err));
        }}
      >
        {inProgress ? (
          <ClipLoader size={10} color="gray" />
        ) : (
          <GlooIcon className="w-2.5 h-auto" />
        )}
        DM
      </SubtitleButton>
    </Tooltip>
  );
};
