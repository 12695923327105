import { groupBy } from 'lodash';
import { useState } from 'react';
import { MdAdd, MdRemove } from 'react-icons/md';
import { ClipLoader } from 'react-spinners';
import { useRecoilValue } from 'recoil';
import { nudgesAtom } from 'renderer/atoms/nudge';
import {
  roomAtom,
  roomAvailableAtom,
  roomUserAtom,
  selectedRoomAvailableAtom,
} from 'renderer/atoms/room';
import {
  FirebaseSyncAvailability,
  selectedTeamAvailableAtom,
  teamAtom,
  teamAvailableAtom,
} from 'renderer/atoms/team';
import {
  DateTimeIntervals,
  intervaledDateTimeAtom,
} from 'renderer/common/hooks/useDateTimeMonitor';
import { Nudge } from 'renderer/models/Api';
import { ToggleIconButton } from 'renderer/shared/Buttons/helpers/ToggleIconButton';
import { RoomIcon } from 'renderer/shared/icons/RoomIcon';
import { UserAvatar } from 'renderer/shared/MiniGloo/user';
import { friendlyTimeDifferenceString } from 'renderer/shared/utilities';

const NudgeStateLoaded: React.FC<{
  nudge: Nudge['incomingPings'][0] | Nudge['outgoingPings'][0];
  type: 'in' | 'out';
}> = ({ nudge, type }) => {
  const userId =
    'senderUserId' in nudge ? nudge.senderUserId : nudge.targetUserId;
  const roomUser = useRecoilValue(
    roomUserAtom({ roomId: nudge.roomId, userId })
  );
  const room = useRecoilValue(roomAtom(nudge.roomId));
  const roomName = room.name;
  const { name: teamName } = useRecoilValue(teamAtom(nudge.teamId));
  const now = useRecoilValue(
    intervaledDateTimeAtom(DateTimeIntervals.SECOND_30)
  );

  return (
    <div className="flex flex-col w-full border-t-[1px] border-black-lightest/20 py-1">
      <div className="flex flex-col text-left text-gray-4">
        <span className="text-xs">{teamName}</span>
        <span className="flex flex-row gap-1 text-xs">
          <RoomIcon room={room} /> {roomName}
        </span>
      </div>
      <div className="flex flex-row items-center gap-2">
        <UserAvatar
          user={{
            name: roomUser.profile.displayName,
            photoUrl: roomUser.profile.photoUrl,
            status: { online: roomUser.online, busy: roomUser.busy },
            userId,
          }}
          showStatus
        />
        <div className="flex flex-col text-left">
          <span className="text-base">
            {roomUser.profile.displayName} {type === 'in' && ' wanted to chat'}
          </span>
          <span className="text-sm italic text-gray-5">
            {friendlyTimeDifferenceString(now, new Date(nudge.timestamp))}
          </span>
        </div>
      </div>
    </div>
  );
};

const NudgeState: React.FC<{
  nudge: Nudge['incomingPings'][0] | Nudge['outgoingPings'][0];
  type: 'in' | 'out';
}> = ({ nudge, type }) => {
  const roomReady = useRecoilValue(roomAvailableAtom({ roomId: nudge.roomId }));
  const teamReady = useRecoilValue(teamAvailableAtom({ teamId: nudge.teamId }));

  return (
    <div className="flex flex-row w-full border-t-[1px] border-black-lightest/20">
      {roomReady === FirebaseSyncAvailability.AVAILABLE &&
      teamReady === FirebaseSyncAvailability.AVAILABLE ? (
        <NudgeStateLoaded nudge={nudge} type={type} />
      ) : (
        <ClipLoader />
      )}
    </div>
  );
};

const NudgeCollection: React.FC<{
  nudges: Nudge['incomingPings'] | Nudge['outgoingPings'];
  type: 'in' | 'out';
}> = ({ nudges, type }) => {
  const show = nudges.length > 0;

  return (
    <div className="px-2 pt-3">
      <div className="flex items-end justify-between w-full pl-1 text-sm text-left">
        <div className="text-sm font-medium text-blue-primary whitespace-nowrap">
          {/* <ToggleIconButton
            handleClick={() => setShow((prev) => !prev)}
            isActive={show}
            active={{ children: <MdAdd /> }}
            inactive={{ children: <MdRemove /> }}
            className="w-4 h-4"
          /> */}
          {nudges.length} {type === 'in' ? 'Received' : 'Sent'} nudges
        </div>
      </div>
      {!show && (
        <div className="w-full border-t-[1px] border-black-lightest/10" />
      )}
      {show &&
        nudges.map((n) => <NudgeState nudge={n} key={n.pingId} type={type} />)}
    </div>
  );
};

const NotificationsPage: React.FC = () => {
  const { incomingPings, outgoingPings } = useRecoilValue(nudgesAtom);

  return (
    <div className="flex flex-col w-full h-full gap-4 pl-2 overflow-x-hidden overflow-y-scroll">
      <NudgeCollection nudges={incomingPings} key="in" type="in" />
      <NudgeCollection nudges={outgoingPings} key="out" type="out" />
    </div>
  );
};

export default NotificationsPage;
