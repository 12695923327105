import { Suspense, useEffect, useMemo, useState } from 'react';
import {
  useRecoilCallback,
  useRecoilTransaction_UNSTABLE,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  agoraClientAtom,
  AgoraState,
  connectionStateAtom,
  remoteUsersAtom,
} from 'renderer/atoms/call';
import { onlineCountRoomAtom, roomAtom } from 'renderer/atoms/room';

import { IAgoraRTCClient, IAgoraRTCRemoteUser } from 'agora-rtc-sdk-ng';
import { useQuery } from 'react-query';
import { trpcAtom } from 'renderer/common/components/TRPCReactQuery';
import LogCreator, { LoggerNames } from 'renderer/common/LogCreator';
import { CAMERA_STREAM_UID_PREFIX } from 'renderer/constants';
import { useDeploymentConfig } from 'renderer/hooks/useDeploymentConfig';
import { RoomKey } from 'renderer/models/Keys';
import { ChildProps } from 'types/react';

import { selfGlooUserImplAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import { availableStatusAtom } from 'renderer/connection/state';
import AgoraPublisher from './agora-publishers/AgoraPublisher';
import AgoraSubscriber from './agora-subscribers/AgoraSubscriber';
import {
  agoraRemoteUserListStreamAtom,
  agoraRemoteUserStreamAtom,
  StreamType,
} from './atoms/CallStateAtoms';

const logger = LogCreator(LoggerNames.CALL);

const DisconnectOnUnload: React.FC<
  RoomKey & { onLeave: () => void; agoraClient: IAgoraRTCClient }
> = ({ roomId, onLeave, agoraClient }) => {
  useEffect(() => {
    logger.info(`Joined call! ${roomId}`);

    return () => {
      logger.info('JoinCallMonitor: leaving meeting.');
      onLeave();
      agoraClient
        .leave()
        .catch((reason) => logger.log(`Failed to leave: ${reason}`));
    };
  }, []);
  return <></>;
};

export const useAgoraListeners = ({ roomId }: RoomKey) => {
  const agora = useRecoilValue(
    agoraClientAtom({ roomId, isCameraStream: false })
  );
  const connectionStateSetter = useSetRecoilState(
    connectionStateAtom({ roomId, isCameraStream: false })
  );
  const setRemoteStatus = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (user: IAgoraRTCRemoteUser, media: 'audio' | 'video') => {
        if (media !== 'audio' && media !== 'video') return;
        const userId = user.uid as string;
        const { stream, available } =
          media === 'audio'
            ? { stream: StreamType.MIC, available: user.hasAudio }
            : {
                stream: userId.includes(CAMERA_STREAM_UID_PREFIX)
                  ? StreamType.VIDEO_CAM
                  : StreamType.SCREENSHARE,
                available: user.hasVideo,
              };

        logger.info('set status', {
          media,
          userId,
          stream,
          available,
        });
        const normalizedUserId = userId.replace('camera-', '');

        set(
          agoraRemoteUserStreamAtom({
            roomId,
            userId: normalizedUserId,
            stream,
          }),
          available
        );

        set(agoraRemoteUserListStreamAtom({ roomId, stream }), (prev) =>
          available
            ? [...prev, normalizedUserId]
            : prev.filter((u) => u !== normalizedUserId)
        );
      }
  );
  const setUserJoined = useRecoilTransaction_UNSTABLE(
    ({ set, get }) =>
      (user: IAgoraRTCRemoteUser) => {
        const selfUser = get(selfGlooUserImplAtom);
        if (!selfUser) {
          throw new Error('Error getting self user');
        }
        const userId = user.uid as string;
        logger.info('User joined callback', { roomId, userId });
        // when we publish the extra camera track from an additional agora client it shows up as a remote user
        // to this agora client so don't sub
        // to it cause it's wasteful
        if (selfUser.userId === userId) {
          return;
        }
        set(remoteUsersAtom({ roomId }), {
          users: Array.from(agora.remoteUsers),
          version: AgoraState.kRemoteUsers,
        });
      }
  );
  const setUserLeft = useRecoilTransaction_UNSTABLE(
    ({ set }) =>
      (user: IAgoraRTCRemoteUser, reason: string) => {
        const userId = user.uid as string;
        logger.info('User left callback', { roomId, userId });

        set(remoteUsersAtom({ roomId }), {
          users: Array.from(agora.remoteUsers),
          version: AgoraState.kRemoteUsers,
        });

        // if the user is a camera track, don't unsub from other streams since they're
        // independent (camera track is published under diff user ID with a prefix)
        const updateUserStreamStatus = (stream: StreamType) => {
          const normalizedUserId = userId.replace(CAMERA_STREAM_UID_PREFIX, '');

          set(
            agoraRemoteUserStreamAtom({
              roomId,
              userId: normalizedUserId,
              stream,
            }),
            false
          );
          set(agoraRemoteUserListStreamAtom({ roomId, stream }), (prev) =>
            prev.filter((u) => u !== normalizedUserId)
          );
        };

        if (userId.includes(CAMERA_STREAM_UID_PREFIX)) {
          updateUserStreamStatus(StreamType.VIDEO_CAM);
        } else {
          [StreamType.MIC, StreamType.SCREENSHARE].forEach((stream) =>
            updateUserStreamStatus(stream)
          );
        }
      }
  );

  useEffect(() => {
    agora.on('connection-state-change', (curState, revState, reason) => {
      logger.info(`Connection state ${revState} -> ${curState} ${roomId}`);
      connectionStateSetter(curState);
    });
    agora.on('exception', (err) => {
      logger.error('Agora client error:', err);
    });

    agora.on('user-left', setUserLeft);
    agora.on('user-joined', setUserJoined);
    agora.on('user-published', setRemoteStatus);
    agora.on('user-unpublished', setRemoteStatus);
    return () => {
      agora.removeAllListeners();
    };
  }, [
    agora,
    connectionStateSetter,
    setRemoteStatus,
    setUserJoined,
    setUserLeft,
    roomId,
  ]);
};

// just ensure we have the right connection state for this 2nd client.
export const useAgoraCameraStreamListeners = ({ roomId }: RoomKey) => {
  const agora = useRecoilValue(
    agoraClientAtom({ roomId, isCameraStream: true })
  );
  const connectionStateSetter = useSetRecoilState(
    connectionStateAtom({ roomId, isCameraStream: true })
  );
  useEffect(() => {
    agora.on('connection-state-change', (curState, revState, reason) => {
      logger.info(`Connection state ${revState} -> ${curState} ${roomId}`);
      connectionStateSetter(curState);
    });
    agora.on('exception', (err) => {
      logger.error('Agora video client error:', err);
    });

    return () => {
      agora.removeAllListeners();
    };
  }, [agora, connectionStateSetter, roomId]);
};

const AfterConnection: React.FC<
  RoomKey &
    ChildProps & {
      onLeave: () => void;
      isCameraStream: boolean;
      agoraClient: IAgoraRTCClient;
    }
> = ({ roomId, children, onLeave, isCameraStream, agoraClient }) => {
  const connectionState = useRecoilValue(
    connectionStateAtom({ roomId, isCameraStream })
  );
  const connected = useMemo(
    () => connectionState === 'CONNECTED',
    [connectionState]
  );

  if (!connected) return null;

  return (
    <>
      {children}
      <DisconnectOnUnload
        roomId={roomId}
        onLeave={onLeave}
        agoraClient={agoraClient}
      />
    </>
  );
};

export const WaitToJoinCall: React.FC<
  RoomKey &
    ChildProps & {
      onLeave: () => void;
      isCameraStream: boolean;
      agoraClient: IAgoraRTCClient;
    }
> = ({ roomId, children, onLeave, isCameraStream, agoraClient }) => {
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const { agoraAppId } = useDeploymentConfig();
  const resetCallStates = useRecoilTransaction_UNSTABLE(
    ({ reset, get }) =>
      () => {
        // dont mess with the main client's connection state when we're just publihsing a video
        // camera track.
        if (isCameraStream) {
          return;
        }
        logger.info('Resetting call states', { roomId, isCameraStream });

        [StreamType.MIC, StreamType.SCREENSHARE, StreamType.VIDEO_CAM].forEach(
          async (stream) => {
            const users = get(
              agoraRemoteUserListStreamAtom({
                roomId,
                stream,
              })
            );
            users.forEach((userId) =>
              reset(agoraRemoteUserStreamAtom({ roomId, userId, stream }))
            );
            reset(agoraRemoteUserListStreamAtom({ roomId, stream }));
          }
        );
      }
  );

  const joinCall = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const trpcClient = await snapshot.getPromise(trpcAtom);
        const { token } = await trpcClient.mutation('meetings.createToken', {
          roomId,
          uidPrefix: isCameraStream ? CAMERA_STREAM_UID_PREFIX : undefined,
        });
        agoraClient.enableAudioVolumeIndicator();
        // This should never really happen, but just in case, we don't want to infinetely retry to join a call we are already in.
        if (agoraClient.connectionState === 'CONNECTED') return;
        // NOTE: since camera track is published on a diff client, we need a unique UID or we get
        // a conflict error.
        const newUid = isCameraStream
          ? CAMERA_STREAM_UID_PREFIX + selfUserId
          : selfUserId;
        await agoraClient.join(agoraAppId, roomId, token, newUid);
      },
    []
  );

  logger.info(`SubTest ${roomId}: WaitToJoinCall: `, {
    roomId,
    agoraAppId,
    selfUserId,
    isCameraStream,
  });

  const { isSuccess } = useQuery({
    queryKey: `join-call-${roomId}`,
    queryFn: async () => {
      // TODO: memory leak here? IIRC we need to .off(..)
      resetCallStates();
      await joinCall();
    },
    retry: true,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    suspense: true,
  });

  if (!isSuccess) return null;

  return (
    <>
      <AfterConnection
        roomId={roomId}
        onLeave={onLeave}
        isCameraStream={isCameraStream}
        agoraClient={agoraClient}
      >
        {children}
      </AfterConnection>
    </>
  );
};

export const useShouldJoinCall = ({ roomId }: RoomKey) => {
  const { active: roomActive, shouldJoinCall: remoteShouldJoinCall } =
    useRecoilValue(roomAtom(roomId));
  const [shouldJoinCall, setShouldJoinCall] = useState(false);
  const onlineUsers = useRecoilValue(onlineCountRoomAtom({ roomId }));
  const minUsersOnline = useMemo(
    () => roomActive && onlineUsers >= 2 && remoteShouldJoinCall,
    [onlineUsers, roomActive, remoteShouldJoinCall]
  );
  const connectionState = useRecoilValue(
    connectionStateAtom({ roomId, isCameraStream: false })
  );
  const connected = useMemo(
    () => connectionState === 'CONNECTED',
    [connectionState]
  );

  useEffect(() => {
    // If we have at least 2 users online, join the meeting.
    if (minUsersOnline) {
      setShouldJoinCall(true);
    } else if (connected) {
      const timeout = setTimeout(() => {
        setShouldJoinCall(false);
      }, 40_000);

      return () => clearTimeout(timeout);
    } else {
      setShouldJoinCall(false);
    }
  }, [minUsersOnline, connected, shouldJoinCall]);

  return { shouldJoinCall, leaveCall: () => setShouldJoinCall(false) };
};

/** Responsible for managing agora connection status.
 *   1. Connect if atleast 2 users are online.
 *   2. If not, after X seconds, disconnect from the call.
 */
export const JoinCallMonitor: React.FC<{
  roomId: string;
}> = ({ roomId }) => {
  const { shouldJoinCall, leaveCall } = useShouldJoinCall({ roomId });

  const agoraClient = useRecoilValue(
    agoraClientAtom({ roomId, isCameraStream: false })
  );

  if (!shouldJoinCall) return null;

  return (
    <Suspense>
      <WaitToJoinCall
        roomId={roomId}
        onLeave={leaveCall}
        isCameraStream={false}
        agoraClient={agoraClient}
      >
        <AgoraSubscriber roomId={roomId} />
        <AgoraPublisher roomId={roomId} />
      </WaitToJoinCall>
    </Suspense>
  );
};
