import { ContentCopyRounded } from '@mui/icons-material';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import { selectedRoomAtom, selectedRoomKeyAtom } from 'renderer/atoms/room';
import { teamAtom } from 'renderer/atoms/team';
import { trpc } from 'renderer/common/client/trpc';
import { useBaseUrl } from 'renderer/hooks/useDeploymentConfig';
import { GlooInviteUserWithProps } from '../user/GlooUser';

const copyTextToClipboard = async (text: string) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text);
    return true;
  }

  return document.execCommand('copy', true, text);
};

const InviteModalContent: React.FC = () => {
  const [isCopied, setIsCopied] = useState(false);
  const selectedRoomId = useRecoilValue(selectedRoomKeyAtom);
  const inviteMutation = trpc.useMutation('invites.get_or_create');
  const baseUrl = useBaseUrl();

  const { data: inviteLink } = useQuery({
    queryFn: async () => {
      const res = await inviteMutation.mutateAsync({ roomId: selectedRoomId });
      return `${baseUrl}/join/${res.inviteId}`;
    },
    queryKey: `invite-${selectedRoomId}`,
    suspense: true,
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout> | undefined;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 1500);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCopied]);

  if (!inviteLink) return null;

  return (
    <div className="relative flex items-center justify-center">
      <input
        className="py-1 pl-2 pr-8 w-80 rounded-xl bg-neutral-2 text-gray-5"
        readOnly
        value={inviteLink}
      />
      <button
        type="button"
        onClick={async () => {
          await copyTextToClipboard(inviteLink);
          setIsCopied(true);
        }}
        className="absolute right-2 text-blue-primary disabled:text-gray-4 hover:text-blue-lighter"
      >
        {isCopied ? (
          <div className="px-1 text-xs rounded-xl bg-blue-primary text-white-1">
            Copied
          </div>
        ) : (
          <ContentCopyRounded />
        )}
      </button>
    </div>
  );
};

const InviteLinkContent: React.FC = () => {
  return (
    <div className="flex flex-col items-center text-black-primary">
      <p>
        <span className="text-sm font-normal text-gray-5">
          Share this invite link
        </span>
      </p>
      <React.Suspense fallback={<div>Generating Link...</div>}>
        <InviteModalContent />
      </React.Suspense>
    </div>
  );
};

const InviteExistingTeammates: React.FC = () => {
  const { teamId, memberList, roomId } = useRecoilValue(selectedRoomAtom);
  const { members, name: teamName } = useRecoilValue(teamAtom(teamId));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const onMutation = {
    onError: (error: any) => {
      setErrorMessage(error.message || 'Unknown error.');
      setTimeout(() => setErrorMessage(null), 4000);
    },
  };

  const activeMembers = useMemo(
    () => members.filter(({ membership }) => !membership.isDeleted),
    [members]
  );

  // Eventually add kick feature.
  const removeUser = trpc.useMutation('rooms.removeUser', onMutation);
  const addUser = trpc.useMutation('rooms.addUser', onMutation);

  if (activeMembers.length <= 1) return null;

  const usersInRoom = activeMembers.filter(({ userId }) =>
    memberList.includes(userId)
  );
  const usersNotInRoom = activeMembers.filter(
    ({ userId }) => !memberList.includes(userId)
  );
  return (
    <>
      <div className="relative flex items-center w-1/2 text-sm">
        <div className="flex-grow border-t-[1px] border-gray-4/10" />
        <span className="flex-shrink mx-4 text-gray-5">or</span>
        <div className="flex-grow border-t-[1px] border-gray-4/10" />
      </div>
      <div className="flex flex-col items-center gap-2">
        {errorMessage && (
          <div className="text-sm italic font-bold text-red-500">
            {errorMessage}
          </div>
        )}
        <p className="text-sm text-center text-gray-5">
          Add from your team (<span className="font-semibold ">{teamName}</span>
          )
        </p>
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex flex-col items-center justify-center p-2 rounded-lg bg-neutral-3/60 max-h-52">
            <div className="flex items-center justify-center rounded-full">
              <div className="px-2 text-sm rounded-xl text-black-lightest/70">
                Already in the room
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center w-full h-full gap-6 px-4 py-4 overflow-x-hidden overflow-y-auto gap-x-8">
              {usersInRoom.map((u) => {
                return (
                  <button
                    type="button"
                    onClick={() =>
                      removeUser.mutate({ userId: u.userId, roomId })
                    }
                  >
                    <GlooInviteUserWithProps
                      iconSizePx={40}
                      photoUrl={u.profile.photoUrl}
                      displayName={u.profile.displayName}
                      userInRoom
                      key={u.userId}
                    />
                  </button>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center p-2 rounded-lg bg-neutral-3/60 max-h-52">
            <div className="flex items-center justify-center rounded-full">
              <div className="px-2 text-sm rounded-xl text-black-lightest/70">
                Not in the room
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-center w-full h-full gap-6 px-4 py-4 overflow-x-hidden overflow-y-auto gap-x-8">
              {usersNotInRoom.map((u) => {
                return (
                  <button
                    type="button"
                    onClick={() => addUser.mutate({ userId: u.userId, roomId })}
                  >
                    <GlooInviteUserWithProps
                      iconSizePx={40}
                      photoUrl={u.profile.photoUrl}
                      displayName={u.profile.displayName}
                      userInRoom={false}
                    />
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const InviteModal: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-black-lightest">
      <InviteLinkContent />
      <InviteExistingTeammates />
    </div>
  );
};
