/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import BedtimeIcon from '@mui/icons-material/Bedtime';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import logger from 'js-logger';
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { buttonPressedTimeAtom } from 'renderer/connection/buttons';
import { availableStatusAtom } from 'renderer/connection/state';
import {
  lastAvailableSelectedStatusAtom,
  selectedUserStatusButtonAtom,
  UserStatus,
} from 'renderer/connection/userStatusRadioGroup';
import { SpatialAudioIcon } from 'renderer/shared/icons/SpatialAudioIcon';
import { Tooltip } from 'renderer/shared/Tooltip';

export const StatusToggle = ({
  isInOfflinePanel,
}: {
  isInOfflinePanel?: boolean;
}) => {
  const [selectedRadioButton, setStatusButton] = useRecoilState(
    selectedUserStatusButtonAtom
  );
  const isAvailable = useRecoilValue(availableStatusAtom);
  const lastAvailableSelectedStatus = useRecoilValue(
    lastAvailableSelectedStatusAtom
  );

  // a hook for mainly listening to state changes due to idleness detection. This hook guarantees the button is in the
  // right state based on the availability signal
  useEffect(() => {
    logger.info('onlinestatus toggle', {
      isAvailable,
      selectedRadioButton,
      lastAvailableSelectedStatus,
    });
    if (!isAvailable) {
      setStatusButton(UserStatus.Offline);
      // this next if means the actual status is AVAILABLE, but button is mismatched.
    } else if (selectedRadioButton === UserStatus.Offline) {
      if (lastAvailableSelectedStatus === null) {
        console.log('setting status button to listening');
        setStatusButton(UserStatus.Listening);
        return;
      }
      setStatusButton(lastAvailableSelectedStatus);
    }
  }, [
    isAvailable,
    lastAvailableSelectedStatus,
    selectedRadioButton,
    setStatusButton,
  ]);

  const iconClassName = 'w-7 h-7 p-[2px]';
  return (
    <>
      <Tooltip
        message={
          selectedRadioButton === UserStatus.Listening ? 'Go async' : 'Go sync'
        }
        placement="top"
      >
        <div
          className={clsx('flex w-fit h-fit', [
            isInOfflinePanel && 'scale-125',
          ])}
        >
          <Switch
            isOn={selectedRadioButton === UserStatus.Listening}
            darkMode={!isAvailable}
            onClick={() => {
              setStatusButton(
                selectedRadioButton === UserStatus.Listening
                  ? UserStatus.Offline
                  : UserStatus.Listening
              );
            }}
          />
        </div>
      </Tooltip>
    </>
  );
};

const Switch = ({
  isOn,
  onClick,
  darkMode,
}: {
  isOn: boolean;
  onClick: () => void;
  darkMode?: boolean;
}) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={onClick}
      // TODO will this work if we do place-content-center for 3 items?
      className={clsx(
        `items-start flex h-[32px] w-[60px] rounded-full p-[3px] shadow-inner hover:cursor-pointer ${
          isOn && 'place-content-end'
        }`,
        [
          isOn
            ? 'bg-blue-lighter'
            : darkMode
            ? 'bg-gray-blue-dark-2'
            : 'bg-gray-2',
        ]
      )}
    >
      <motion.div
        className="flex h-[26px] w-[26px] items-center justify-center rounded-full bg-white-1"
        layout
        transition={spring}
      >
        <motion.div whileTap={{ rotate: 10 }}>
          {isOn ? (
            <SpatialAudioIcon className="!w-4 !h-4 text-blue-primary" />
          ) : (
            <BedtimeIcon className="!w-4 !h-4 text-gray-5" />
          )}
        </motion.div>
      </motion.div>
    </div>
  );
};
