import { NotificationPanelState, SelfUser } from 'api/models';
import RoomControllerBase from './RoomContorllerBase';

export const RoomController: React.FC<{
  meta: NotificationPanelState['room'][0]['meta'];
  activeConvo: NonNullable<NotificationPanelState['room'][0]['activeConvo']>;
  self: SelfUser;
}> = ({ meta, activeConvo, self }) => {
  const isMicOn = !!self.activeButtonRoomIds.mic;
  const micActiveInRoom = self.activeButtonRoomIds.mic === meta.roomId;
  const isVideoOn = !!self.activeButtonRoomIds.video;
  const videoActiveInRoom = self.activeButtonRoomIds.video === meta.roomId;
  const isScreenOn = !!self.activeButtonRoomIds.screenshare;
  const screenActiveInRoom =
    self.activeButtonRoomIds.screenshare === meta.roomId;

  return (
    <RoomControllerBase
      show
      leaveTimer={activeConvo.leavingConvoTime}
      onLeave={async () =>
        window.electron?.ipcRenderer.setButtonState({
          button: 'EXIT_CONVO',
          roomId: meta.roomId,
          teamId: meta.teamId,
        })
      }
      mic={{
        active: isMicOn,
        currRoomActive: micActiveInRoom,
        onClick: async () =>
          window.electron?.ipcRenderer.setButtonState({
            button: 'MIC',
            roomId: meta.roomId,
            teamId: meta.teamId,
            enabled: !micActiveInRoom,
            convoId: activeConvo.convoId,
          }),
      }}
      video={{
        active: isVideoOn,
        currRoomActive: videoActiveInRoom,
        onClick: async () =>
          window.electron?.ipcRenderer.setButtonState({
            button: 'VIDEO',
            roomId: meta.roomId,
            teamId: meta.teamId,
            enabled: !videoActiveInRoom,
            convoId: activeConvo.convoId,
          }),
      }}
      screen={{
        active: isScreenOn,
        currRoomActive: screenActiveInRoom,
        onClick: async () =>
          window.electron?.ipcRenderer.setButtonState({
            button: 'SCREENSHARE',
            roomId: meta.roomId,
            teamId: meta.teamId,
            enabled: !screenActiveInRoom,
            convoId: activeConvo.convoId,
          }),
      }}
      darkMode
      miniView
    />
  );
};
