import { Popover, Transition } from '@headlessui/react';
import { motion } from 'framer-motion';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { isMeetingDetectionEnabledAtom } from 'renderer/atoms/settings';

export const MeetingDetectionPanel = () => {
  const [isMeetingDetectionEnabled, enableMeetingDetection] = useRecoilState(
    isMeetingDetectionEnabledAtom
  );

  // web versions don't use meeting detection
  if (!window.electron) {
    return null;
  }

  return (
    <>
      <div className="flex flex-row items-center justify-between w-full p-1 px-1 text-xs font-light rounded-lg lg:text-base h-fit text-black-lightest">
        <div className="flex flex-row gap-1">
          Go
          <Popover className="relative">
            <Popover.Button className="underline text-gray-4 decoration-gray-4">
              async during calls
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-20 p-2 overflow-hidden text-sm text-left whitespace-pre-line border border-gray-200 shadow-md bg-white-1 bottom-5 -right-28 w-52 rounded-xl">
                <>
                  If enabled, Gloo will set your status as Async when you join a
                  meeting on any conferencing app. Disable it if not working as
                  intended.
                </>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>

        <Switch
          isOn={isMeetingDetectionEnabled}
          onClick={() => {
            enableMeetingDetection(!isMeetingDetectionEnabled);
          }}
        />
      </div>
    </>
  );
};

const Switch = ({ isOn, onClick }: { isOn: boolean; onClick: () => void }) => {
  const spring = {
    type: 'spring',
    stiffness: 700,
    damping: 30,
  };

  return (
    <div
      onClick={onClick}
      // TODO will this work if we do place-content-center for 3 items?
      className={`items-start flex h-[14px] w-[28px] rounded-full p-[2px] shadow-inner hover:cursor-pointer ${
        isOn ? 'bg-blue-lighter' : 'bg-gray-2'
      } ${isOn && 'place-content-end'}`}
    >
      <motion.div
        className="flex h-[10px] w-[10px] items-center justify-center rounded-full bg-white-1"
        layout
        transition={spring}
      >
        {/* <motion.div whileTap={{ rotate: 10 }}>
          {isOn ? (
            <SpatialAudioIcon className="!w-4 !h-4 text-blue-primary" />
          ) : (
            <BedtimeIcon className="!w-4 !h-4 text-gray-5" />
          )}
        </motion.div> */}
      </motion.div>
    </div>
  );
};
