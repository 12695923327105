import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { trpc } from 'renderer/common/client/trpc';
import { availableStatusAtom } from 'renderer/connection/state';
import { AppInfoContext } from 'renderer/providers/AppInfoProvider';
import _ from 'lodash';

import LogCreator from 'renderer/common/LogCreator';
import { totalNotifsAtom } from 'renderer/chat/atoms';

const logger = LogCreator();

/** Responsible for regularly pinging the backend with status changes. */

/** Responsible for regularly pinging the backend with status changes. */
export const OnlineStatusMonitor = () => {
  const [isMonitorActive, setMonitorActive] = useState(false);

  // TODO: until we fix all the issues with conflicts etc we wait to send the first status
  // since initially the available status changes very often.
  useEffect(() => {
    if (!isMonitorActive) {
      setTimeout(() => setMonitorActive(true), 2000);
    }
  }, [isMonitorActive]);

  const appInfo = useContext(AppInfoContext);
  const isAvailable = useRecoilValue(availableStatusAtom);
  const updateOnlineStatusMutation = trpc.useMutation(['users.status.online'], {
    retry: 2,
    retryDelay: (attempt) => attempt * 1000,
  });
  const notifCount = useRecoilValue(totalNotifsAtom);

  const currentStatus = isAvailable;

  useEffect(() => {
    window?.electron?.ipcRenderer.setState(
      isAvailable ? 'online' : 'offline',
      notifCount
    );
  }, [isAvailable, notifCount]);

  useEffect(() => {
    const updateStatus = async () => {
      logger.info('OnlineStatusMonitor: onlinestatus', currentStatus);

      await updateOnlineStatusMutation.mutateAsync(
        {
          online: isAvailable,
        },
        {
          onError: (err) => {
            logger.error('Error updating status', err);
          },
        }
      );
    };

    if (!isMonitorActive) {
      return;
    }

    updateStatus();
    const interval = setInterval(() => {
      updateStatus();
    }, 60_000);
    return () => {
      clearInterval(interval);
    };
    // We explicitly don't include updateOnlineStatusMutation as it always change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appInfo?.appVersion, appInfo?.machineId, currentStatus, isMonitorActive]);

  return <></>;
};
