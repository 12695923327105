import clsx from 'clsx';
import { LayoutGroup, motion } from 'framer-motion';
import { useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  ConnectionStatusEnum,
  connectionStatusAtom,
} from 'renderer/atoms/connectionStatus';
import { selfGlooUserAtom, selfUserIdAtom } from 'renderer/atoms/glooUser';
import {
  roomConvoAtom,
  roomUserAtom,
  selectedRoomKeyAtom,
} from 'renderer/atoms/room';
import { roomGroupsAtom } from 'renderer/atoms/userUtils';
import { chatContextAtom } from 'renderer/chat/atoms';
import { availableStatusAtom } from 'renderer/connection/state';
import { BubbleWrapper, SingleUserInGrid } from './Bubble';
import { ErrorMessage } from './ErrorMessage';
import { InviteButton } from './InviteButton';
import { OfflinePanel } from './OfflinePanel';
import { RoomController } from './RoomController/MainAppRoomController';
import { RoomConnectionDot } from './RoomTitleBar';

export const BubbleGrid = () => {
  const selfUserId = useRecoilValue(selfUserIdAtom);
  const roomId = useRecoilValue(selectedRoomKeyAtom);
  const selfUserConnected =
    useRecoilValue(connectionStatusAtom({ roomId, userId: selfUserId })) ===
    ConnectionStatusEnum.Online;
  const tableGroups = useRecoilValue(roomGroupsAtom({ roomId }));

  const isSelfInAnyTable = tableGroups.SELF_USER_CONVO_IDX !== -1;
  const selfUserConvo = isSelfInAnyTable
    ? tableGroups.CONVOS[tableGroups.SELF_USER_CONVO_IDX]
    : undefined;

  const isAnyoneElseOnline =
    tableGroups.CONVOS.length > 0 || tableGroups.USERS_NOT_IN_CONVO.length > 1;
  const isAnyoneElseInRoom =
    isAnyoneElseOnline || tableGroups.USERS_AWAY.length > 0;

  // eslint-disable-next-line no-nested-ternary
  let roomMessage = selfUserConnected
    ? isAnyoneElseOnline
      ? 'Click on a teammate to start a conversation'
      : 'Everyone else seems to be async at this moment'
    : 'Go online to chat';
  if (!isAnyoneElseInRoom) {
    roomMessage = 'Add members to start conversations!';
  }

  return (
    // if the chat is present and view is large, we make this 65%. See ChatContainer, where we add a dummy div that's 35%
    <div
      className={clsx(
        'relative flex flex-col items-center w-full px-2 overflow-clip',
        [
          selfUserConnected
            ? 'w-full h-full md:w-[65%] lg:w-[50%]'
            : 'h-[30%] w-full md:h-full md:w-[50%] lg:w-[50%] md:px-4',
        ]
      )}
    >
      <div className="absolute left-0 z-10 flex gap-1 px-4 py-1">
        <OfflinePanel />
        <RoomConnectionDot />
      </div>
      <div
        className={clsx(
          'flex flex-col items-center justify-start flex-grow w-full gap-8 px-1 pb-12 selection:z-10 lg:gap-14 overflow-x-clip overflow-y-auto',
          selfUserConnected ? 'pt-12' : 'pt-4'
        )}
      >
        <ErrorMessage />
        <LayoutGroup>
          <div className="absolute z-10 flex justify-center">
            <RoomController roomId={roomId} />
          </div>
          <div className="flex flex-col justify-start gap-4 lg:gap-12">
            {selfUserConnected && !isSelfInAnyTable && (
              <div className="pt-3 text-gray-4">{roomMessage}</div>
            )}
            <div className="flex flex-row items-end justify-center pb-3 gap-x-3 lg:transform lg:origin-center lg:scale-125">
              {!isSelfInAnyTable && (
                <SingleUserInGrid
                  roomId={roomId}
                  userId={selfUserId}
                  key={selfUserId}
                />
              )}
            </div>
            {selfUserConvo && (
              <div className="flex flex-row flex-wrap items-center justify-center w-full gap-16">
                {/* <div className="flex "> */}
                <BubbleWrapper
                  roomId={roomId}
                  convoId={selfUserConvo.convoId}
                  userIds={selfUserConvo.userIds}
                  convoType={selfUserConvo.type}
                  key={selfUserConvo.convoId}
                />
                {/* </div> */}
              </div>
            )}
            <div className="flex flex-row flex-wrap items-center justify-center w-full gap-6 pt-3 lg:gap-16">
              {/** Other Tables */}
              {tableGroups.CONVOS.filter(
                ({ type, selfPresent }) => type === 'EVENT' && !selfPresent
              ).map(({ convoId: tableId, userIds, type }) => (
                <div
                  className="flex lg:transform lg:origin-center lg:scale-125"
                  key={tableId}
                >
                  <BubbleWrapper
                    roomId={roomId}
                    convoId={tableId}
                    userIds={userIds}
                    convoType={type}
                    key={tableId}
                  />
                </div>
              ))}
              {tableGroups.CONVOS.filter(
                ({ type, selfPresent }) => type === 'CONVO' && !selfPresent
              ).map(({ convoId: tableId, userIds, type }) => {
                return (
                  <div
                    className="flex lg:transform lg:origin-center lg:scale-125"
                    key={tableId}
                  >
                    <BubbleWrapper
                      roomId={roomId}
                      convoId={tableId}
                      userIds={userIds}
                      convoType={type}
                      key={tableId}
                    />
                  </div>
                );
              })}
              {tableGroups.USERS_NOT_IN_CONVO.filter(
                ({ userId }) => userId !== selfUserId
              ).map(({ userId }) => (
                <div
                  className="flex lg:transform lg:origin-center lg:scale-125"
                  key={userId}
                >
                  <SingleUserInGrid
                    roomId={roomId}
                    userId={userId}
                    key={userId}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex flex-row flex-wrap items-center justify-center gap-4 lg:gap-10">
            {tableGroups.USERS_AWAY.filter(
              ({ userId }) => userId !== selfUserId
            ).map(({ userId }) => (
              <div
                className="flex lg:transform lg:origin-center lg:scale-125"
                key={userId}
              >
                <SingleUserInGrid
                  roomId={roomId}
                  userId={userId}
                  key={userId}
                />
              </div>
            ))}
          </div>
        </LayoutGroup>
        {/*
      Removed due to performance reasons.
      <TableCursor roomId={roomId} />
      */}
      </div>
    </div>
  );
};
