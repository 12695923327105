import { useRadioGroup } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { MdHelpOutline, MdPause, MdPlayArrow } from 'react-icons/md';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  AmbientSoundMode,
  ambientSoundModeAtom,
  ambientSoundModeDescription,
  ambientSoundModeName,
  ambientSoundVolumeAtom,
  AmbientSoundVolumeConfig,
} from 'renderer/atoms/ambientSound';
import {
  makeFilterFarAway,
  makeFilterMuffled,
} from 'renderer/audio/OverhearingFilters';
import { AmbientSoundSlider } from 'renderer/pages/dashboard/components/SidePanel/StatusPanel/AmbientSoundPanel/AmbientSoundSlider';
import ConvoSample from 'resources/audio/conversation_sample.ogg';
import ConvoSample2Steve from 'resources/audio/conversation_sample2_steve.mp3';
import { AudioContext } from 'standardized-audio-context';
import { SubtitleButton } from '../SimpleButton';

const AmbientSoundModeSelector: React.FC = () => {
  const [mode, setMode] = useRecoilState(ambientSoundModeAtom);
  return (
    <div className="flex flex-col gap-2 text-sm">
      {[
        AmbientSoundMode.ALL_CONVERSATIONS,
        AmbientSoundMode.LONG_CONVERSATIONS,
        AmbientSoundMode.NEVER,
      ].map((m) => (
        <div className="flex flex-col justify-start gap-0.5" key={m}>
          <div className="flex items-center gap-1">
            <input
              type="radio"
              onClick={() => setMode(m)}
              checked={m === mode}
              onChange={() => {}}
            />
            {ambientSoundModeName(m)}
          </div>
          <span className="max-w-sm ml-4 text-xs italic">
            {ambientSoundModeDescription(m)}
          </span>
        </div>
      ))}
    </div>
  );
};

const AmbientSoundVolumeSelector: React.FC = () => {
  const [vol, setVol] = useRecoilState(ambientSoundVolumeAtom);
  const [inPreview, setInPreview] = useState(false);

  const { audioNode, gainNode } = useMemo(() => {
    const sample = new Audio(ConvoSample2Steve);
    sample.loop = true;
    const audioContext = new AudioContext();
    const source = audioContext.createMediaElementSource(sample);
    const gain = audioContext.createGain();
    source.connect(gain);
    const filter = audioContext.createBiquadFilter();
    // TODO: Allow users to pick which filter they use.
    makeFilterFarAway(filter);
    // makeFilterMuffled(filter);
    gain.connect(filter);

    // const destination = audioContext.createMediaStreamDestination();
    filter.connect(audioContext.destination);
    // source.connect(audioContext.destination);
    return { audioNode: sample, gainNode: gain, audioContext };
  }, []);

  useEffect(() => {
    gainNode.gain.value = vol / 100;
  }, [vol]);

  useEffect(() => {
    if (inPreview) {
      audioNode.currentTime = 0;
      audioNode.play();

      return () => {
        audioNode.pause();
      };
    }
  }, [inPreview]);

  return (
    <div className="flex flex-col items-center gap-1">
      <AmbientSoundSlider
        isMuted={false}
        displayedVolume={vol}
        min={AmbientSoundVolumeConfig.Min}
        max={AmbientSoundVolumeConfig.Max}
        step={1 / AmbientSoundVolumeConfig.Scale}
        onChange={(values) => {
          setVol(values[0]);
        }}
      />
      <span className="text-xs">
        Pick your prefered volume for ambient sound.
      </span>
      <SubtitleButton clickHandler={() => setInPreview((p) => !p)}>
        {inPreview ? <MdPause /> : <MdPlayArrow />}
        Preview
      </SubtitleButton>
    </div>
  );
};

export const AmbientSoundModal: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-4 text-black-lightest">
      <div className="flex items-start gap-1 text-black-lightest">
        <MdHelpOutline />
        <span className="max-w-xs text-xs">
          Ambient sound keeps you connected with your team in the way that best
          suits your working style. You&apos;ll be able to hear relevant
          conversations that are happening around you at whatever cadence you
          prefer.
        </span>
      </div>

      <AmbientSoundModeSelector />
      <AmbientSoundVolumeSelector />
    </div>
  );
};
