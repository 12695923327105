import { hoursToMilliseconds } from 'date-fns';
import LRUCache from 'lru-cache';
import { atomFamily, selector } from 'recoil';
import { selfGlooUserAtom } from './glooUser';

export type CalendarAccount = {
  accountEmail: string;
  accountId: string;
  calendarsLinked: {
    calendarId: string;
    name: string;
    color?: string;
    enabled: boolean;
  }[];
  provider: 'microsoft' | 'google';
};

export const linkedCalendarsAtom = selector<{
  google: CalendarAccount[];
  microsoft: CalendarAccount[];
}>({
  key: 'linkedCalendarsAtom',
  get: ({ get }) => {
    const { userIntegrations } = get(selfGlooUserAtom);

    const result: {
      google: CalendarAccount[];
      microsoft: CalendarAccount[];
    } = {
      google: [],
      microsoft: [],
    };
    userIntegrations?.accounts.forEach((a) => {
      if ('calendars' in a.provider) {
        const calendars = a.provider.calendars.map((c) => {
          return {
            enabled: c.enabled,
            calendarId: c.id,
            name: c.name || 'Untitled',
            color: c.color || '#000000',
          };
        });
        const linkedAcc: CalendarAccount = {
          accountId: a.accountId,
          accountEmail: a.accountEmail,
          calendarsLinked: calendars,
          provider: a.provider.type,
        };
        if (a.provider.type === 'google') {
          result.google.push(linkedAcc);
        } else if (a.provider.type === 'microsoft') {
          result.microsoft.push(linkedAcc);
        }
      }
    });
    return result;
  },
});

export const linkedCalendarAtom = selector<CalendarAccount | null>({
  key: 'linkedCalendarAtom',
  get: ({ get }) => {
    const cals = get(linkedCalendarsAtom);
    if (cals.google.length > 0) {
      return cals.google[0];
    }
    if (cals.microsoft.length > 0) {
      return cals.microsoft[0];
    }
    return null;
  },
});

// returns true if given eventId is snoozed
export const snoozedEventsAtom = atomFamily<
  boolean,
  { eventId: string; snoozeTime: Date }
>({
  key: 'snoozedEvents',
  default: false,
});

export const snoozedEventsCache = new LRUCache<string, string>({
  max: 50,

  // for use with tracking overall storage size
  maxSize: 5000,
  sizeCalculation: (value, key) => {
    return 1;
  },

  // how long to live in ms
  ttl: hoursToMilliseconds(48),
  allowStale: false,

  updateAgeOnGet: false,
  updateAgeOnHas: false,
});
