import { PageSelector } from 'renderer/atoms/pageselectors';
import { Room } from 'renderer/models/Api';
import { MdGroups, MdRoom, MdSettings } from 'react-icons/md';
import { GlooIcon } from 'renderer/shared/icons/GlooIcon';
import SlackIcon from 'renderer/shared/icons/SlackIcon';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { BsBell } from 'react-icons/bs';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { GlooUserSidePanel } from 'renderer/shared/user/GlooUser';
import { useRecoilValue } from 'recoil';
import { selfUserIdAtom } from 'renderer/atoms/glooUser';

export const usePageAttributes = (page: PageSelector, room: Room | null) => {
  const selfId = useRecoilValue(selfUserIdAtom);
  switch (page) {
    case PageSelector.TEAM_ROOM: {
      if (!room) return { title: '', description: undefined, icon: null };
      const getIcon = () => {
        switch (room.type) {
          case 'SLACK':
            return <SlackIcon className="w-3/4 h-3/4" />;
          case 'DM': {
            if (room.memberList.length > 2) {
              return <MdGroups className="w-full h-full" />;
            }
            const otherUser = room.memberList.find((m) => m !== selfId);
            if (!otherUser) return null;
            return (
              <GlooUserSidePanel
                userId={otherUser}
                roomId={room.roomId}
                iconSizePx={16}
                withStatus
              />
            );
          }
          case 'DEFAULT':
          default:
            if (room?.private) {
              return <LockRoundedIcon className="!w-[100%] !h-[100%]" />;
            }
            return <GlooIcon className="w-full h-full" />;
        }
      };
      return {
        title: room.name ?? '',
        description: room.description,
        icon: getIcon(),
      };
    }
    case PageSelector.TEAM_SETTINGS:
      return {
        title: 'Settings',
        description: 'Some features here are admin only',
        icon: MdSettings({ className: 'w-full h-full' }),
      };
    case PageSelector.TEAM_ROOMS_OVERVIEW:
      return {
        title: 'Channels',
        icon: MdRoom({ className: 'w-full h-full' }),
      };
    case PageSelector.TEAM_USER_OVERVIEW:
      return {
        title: 'Direct Messages',
        icon: MdGroups({ className: 'w-full h-full' }),
      };
    case PageSelector.NOTIFICATIONS_PAGE:
      return {
        title: 'Nudges',
        icon: <NotificationsNoneOutlinedIcon className="!w-8 h-8" />,
      };
    default:
      throw new Error('Unsupported page type');
  }
};
